import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',

  height: theme.typography.pxToRem(1080),
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('xl')]: {
    height: theme.typography.pxToRem(720),
  },
  [theme.breakpoints.down('lg')]: {
    height: theme.typography.pxToRem(1024),
    backgroundColor: theme.palette.common.white,
  },
  [theme.breakpoints.down('md')]: {
    height: theme.typography.pxToRem(640),
  },
  '& .VCSOverlay': {
    background:
      'linear-gradient(257deg, rgba(0, 0, 0, 0.00) 32.87%, rgba(0, 0, 0, 0.56) 68.16%, rgba(0, 0, 0, 0.71) 84.52%), rgba(0, 0, 0, 0)',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  '& .HSVideo, .IBimgbox': {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: 'cover',
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  '& .VSSContent': {
    position: 'relative',
    zIndex: 2,
    maxWidth: theme.typography.pxToRem(610),
    marginBottom: theme.typography.pxToRem(150),

    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.typography.pxToRem(88),
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.spacing(422),
      marginBottom: theme.typography.pxToRem(51),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.typography.pxToRem(80),
    },
  },
  '& .VCSTextTitle': {
    color: theme.palette.common.fogWhite,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.typography.pxToRem(26),
    },
  },
  '& .VCSTextDes': {
    color: theme.palette.common.fogWhite,
  },
  '& .VSCTextBtn': {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.typography.pxToRem(35),
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.fogWhite,
      color: theme.palette.common.black,
    },
  },
}));

export default StyledBox;
