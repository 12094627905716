import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ImgBox } from 'views/common/components';
import { useSelector } from 'react-redux';

const Video = (props) => {
  const {
    videoSrc = '',
    fallbackImg = {
      src: '',
      alt: '',
      height: null,
      width: null,
    },
    ...restOfProps
  } = props;
  const { isCrawler } = useSelector((state) => state.globalData);
  const [renderVideo, setRenderVideo] = useState(!isCrawler);

  const getNetworkInfomation = () => {
    if ('navigator' in window && navigator.connection) {
      return navigator.connection;
    }
    return null;
  };

  const isStrongConnection = () => {
    const networkInfo = getNetworkInfomation();
    if (!networkInfo) return true;
    if (networkInfo.downlink >= 5) return true;
    return false;
  };

  useEffect(() => {
    setRenderVideo(isStrongConnection());
  }, []);

  return renderVideo ? (
    <Box
      component="video"
      preload="auto"
      autoPlay
      muted
      playsInline
      loop
      className="VVideo"
      {...restOfProps}>
      <Box component="source" src={videoSrc} type="video/mp4" />
    </Box>
  ) : (
    <ImgBox
      image={{
        src: fallbackImg.src,
        alt: fallbackImg.alt,
      }}
      imgHeight={fallbackImg.height}
      imgWidth={fallbackImg.width}
      imgObjectFit="cover"
      unoptimized={false}
      {...restOfProps}
    />
  );
};

export { Video };
