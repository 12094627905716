import React, { useEffect } from 'react';
import {
  IntroSection,
  VideoCalloutSection,
  CardsLayoutSection,
  HeroSection,
  OffersSection,
  UserGeneratedContentSection,
  IntroSectionSkeleton,
  VideoCalloutSectionSkeleton,
  CardsLayoutSectionSkeleton,
  OffersSectionSkeleton,
  UserGenerationContentSkeleton,
} from 'views/rebranded-module/HomeViewV2/components';
import StyledBox from 'views/rebranded-module/HomeViewV2/HomeViewV2.styles';
import {
  InteractiveMapWrapper,
  InteractiveMapSkeleton,
} from 'views/rebranded-module/common/components';
import { ImgBox, LazyLoad } from 'views/common/components';
import { Box } from '@mui/material';
import Head from 'next/head';
import generateMetaData from 'lib/utils/generateMetaData';
import { useDispatch, useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { setGoogleAnalytics } from 'redux/features/globalData-slice';
import { GA_EVENT_CATEGORY } from 'lib/utils/CONSTANTS';
import { PAGES_GA_IDENTIFIERS } from 'views/common/utils/constants';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const HomeViewV2 = (props) => {
  const { serverData: homeData, initWidgetData } = props;
  const dispatch = useDispatch();
  const { googleAnalytics } = useSelector((state) => state.globalData);
  useEffect(() => {
    hotjar.initialize(envVars.HOTJAR_SITE_ID, 6);
    dispatch(
      setGoogleAnalytics({
        ...googleAnalytics,
        newsLetterEventCategory: GA_EVENT_CATEGORY.home,
      }),
    );
    window.gtag('event', 'landing_page_view', {
      page_identifier: PAGES_GA_IDENTIFIERS.HOME,
    });
  }, []);

  return (
    <StyledBox>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {homeData?.seo_values && generateMetaData(homeData.seo_values)}
      </Head>

      <Box className="HVBGImg">
        <ImgBox
          image={{
            src: '/imgs/Rebranding/HomePage/Home-bg.png',
            alt: 'Home background',
          }}
          imgHeight="100%"
          imgWidth="100%"
          unoptimized
        />
      </Box>
      <Box className="HVPageContent">
        <HeroSection initWidgetData={initWidgetData} />
        <LazyLoad skeleton={<IntroSectionSkeleton />} dataLoaded>
          <IntroSection />
        </LazyLoad>
        <LazyLoad skeleton={<VideoCalloutSectionSkeleton />} dataLoaded>
          <VideoCalloutSection />
        </LazyLoad>
        <LazyLoad skeleton={<CardsLayoutSectionSkeleton />} dataLoaded>
          <CardsLayoutSection />
        </LazyLoad>
        {homeData.promotions.length > 0 && (
          <LazyLoad skeleton={<OffersSectionSkeleton />} dataLoaded>
            <OffersSection offers={homeData.promotions} />
          </LazyLoad>
        )}
        <LazyLoad skeleton={<InteractiveMapSkeleton />} dataLoaded>
          <InteractiveMapWrapper />
        </LazyLoad>
        <LazyLoad skeleton={<UserGenerationContentSkeleton />} dataLoaded>
          <UserGeneratedContentSection />
        </LazyLoad>
      </Box>
    </StyledBox>
  );
};

export { HomeViewV2 };
