import React from 'react';
import {
  Container, Typography, Grid, Box, Button,
} from '@mui/material';
import StyledBox from 'views/rebranded-module/HomeViewV2/components/UI/IntroSection/IntroSection.styles';
import { useTranslation } from 'next-i18next';
import { ImgBox, Typography700 } from 'views/common/components';
import useAdabtiveMultiSizeImage from 'views/common/hooks/useAdabtiveMultiSizeImage';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';

const IntroSection = () => {
  const { t } = useTranslation('fe_er_home_page_V2');
  const getSizedImage = useAdabtiveMultiSizeImage();
  const interoImg = {
    large_url: '/imgs/Rebranding/HomePage/LGIntro.png',
    medium_url: '/imgs/Rebranding/HomePage/MDIntro.png',
    small_url: '/imgs/Rebranding/HomePage/SMIntro2.jpg',
    alt: 'intero-image',
  };

  const introImgSrc = getSizedImage(interoImg);

  return (
    <StyledBox>
      <Box className="ISBGImg">
        <ImgBox
          image={{
            src: '/imgs/Rebranding/HomePage/IntroSectionBG.png',
            alt: 'section-BG',
          }}
          imgHeight="100%"
          imgWidth="100%"
        />
      </Box>
      <Grid
        container
        columnSpacing={{
          xl: 7,
          lg: 0,
        }}>
        <Grid item lg={7} md={12} sm={12} className="ISLefSide">
          <Box className="ISIntroImg">
            <ImgBox
              image={{ src: introImgSrc, alt: interoImg.alt }}
              data-src={introImgSrc}
              imgHeight="100%"
              imgWidth="100%"
              imgObjectFit="cover"
              unoptimized
            />
          </Box>
          <Box className="ISSubImg showTABLET">
            <ImgBox
              image={{
                src: '/imgs/Rebranding/HomePage/subInto.png',
                alt: 'sub-intero-image',
              }}
              imgHeight="100%"
              imgWidth="100%"
              imgObjectFit="cover"
              unoptimized
            />
          </Box>
        </Grid>
        <Grid item lg={5} md={12} sm={12} className="ISRightSide">
          <Container>
            <Box className="ISTextBox">
              <Typography700
                variant="h3"
                component="h3"
                className="ISTextTitle">
                {t('fe_er_home_page_V2:born_passion_open_road')}
              </Typography700>
              <Typography variant="bodyXL">
                {t('fe_er_home_page_V2:born_passion_open_road_disc')}
              </Typography>
              <Button
                component={NavigatorLink}
                variant="contained"
                className="ISTextBtn"
                href={PATHS.locations()}>
                {t('fe_er_home_page_V2:our_destinations')}
              </Button>
            </Box>
            <Box className="ISSubImg  showDESKTOP">
              <ImgBox
                image={{
                  src: '/imgs/Rebranding/HomePage/subInto.png',
                  alt: 'sub-intero-image',
                }}
                imgHeight="100%"
                imgWidth="100%"
                imgObjectFit="cover"
              />
            </Box>
          </Container>
        </Grid>
      </Grid>
    </StyledBox>
  );
};
export { IntroSection };
