import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowYellow = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="62"
    viewBox="0 0 38 62"
    fill="none">
    <path
      d="M9.83299 3.00007L6.33585 3.00011L8.10923 6.01426L22.8096 31L8.10923 55.9858L6.33581 59L9.83303 59L16.1223 58.9999L17.2631 58.9999L17.8438 58.0181L33.2211 32.0182L33.8233 31L33.2211 29.9819L17.8438 3.98187L17.2631 2.99999L16.1223 3L9.83299 3.00007Z"
      fill="#EDC14F"
      stroke="#EDC14F"
      strokeWidth="4"
      className="__web-inspector-hide-shortcut__"
    />
  </SvgIcon>
);

export default ArrowYellow;
