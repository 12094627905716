import React from 'react';
import {
  Box, Skeleton, styled, Grid, Container,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  padding: theme.spacing(6, 0),
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.grey[700],
  },
}));
const CardsLayoutSectionSkeleton = () => (
  <StyledBox>
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item lg={4} md={12} sm={12}>
          <Skeleton variant="rectangular" width="100%" height={800} />
        </Grid>
        <Grid item lg={4} md={12} sm={12}>
          <Skeleton variant="rectangular" width="100%" height={800} />
        </Grid>
        <Grid item lg={4} md={12} sm={12}>
          <Skeleton variant="rectangular" width="100%" height={800} />
        </Grid>
      </Grid>
    </Container>
  </StyledBox>
);
export { CardsLayoutSectionSkeleton };
