import React, { useEffect, useState } from 'react';
import GoogleMap from 'google-map-react';
import { mapDarkStyle } from 'views/rebranded-module/common/components/UI/InteractiveMap/utils/mapDarkStyle';
import StyledBox from 'views/rebranded-module/common/components/UI/InteractiveMap/InteractiveMap.styles';
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Typography700 } from 'views/common/components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import PATHS from 'lib/navigation/paths';
import { useTranslation } from 'next-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const InteractiveMap = (props) => {
  const {
    markers = [],
    defaultCenter,
    defaultZoom,
    regions,
    handleRegionClick,
  } = props;
  const { t } = useTranslation();

  const [mapCtl, setMapCtl] = useState();
  const [openAccordion, setOpenAccordion] = useState(false);
  const mapOptions = (maps) => ({
    fullscreenControl: false,
    styles: mapDarkStyle,
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
      style: maps.ZoomControlStyle.SMALL,
    },
  });

  const handleRegionBtnClick = (region) => {
    handleRegionClick(region, mapCtl);
    setOpenAccordion(false);
  };
  const handleAccordionChange = (e, expanded) => {
    setOpenAccordion(expanded);
  };

  const renderRegions = () => regions.map((region) => (
    <Box key={region.name}>
      <Button
        className="IMRegionBtn"
        variant="text"
        onClick={() => {
          handleRegionBtnClick(region);
        }}
        endIcon={
          <ArrowForwardIosIcon className="IMArrowIcon" color="primary" />
          }>
        {region.name}
      </Button>
    </Box>
  ));

  useEffect(() => {
    if (mapCtl?.map) handleRegionClick(regions[0], mapCtl);
  }, [mapCtl]);

  return (
    <StyledBox id="interactive-map-container">
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: envVars.GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={setMapCtl}
        options={mapOptions}>
        {markers.map((marker) => (
          <Box key={marker.id} lat={marker.latitude} lng={marker.longitude}>
            <img
              className="IMLocPin"
              src="/imgs/map-pin.svg"
              alt="Eaglerider location pin"
            />
          </Box>
        ))}
      </GoogleMap>

      <Box
        className="IMLocationsContainer"
        p={{
          xl: 10,
          lg: 7.5,
          md: 4.75,
          sm: 4.5,
        }}>
        <Typography700 variant="h5" component="h2">
          {t('common_interactive_map:explore_our_locations')}
        </Typography700>
        <Typography
          my={4}
          color="grey.pavement"
          variant="body1"
          component="p"
          className="IMDesc">
          {t('common_interactive_map:explore_desc')}
        </Typography>
        <Box>
          <Box className="showTabletAndDesktop">
            <Typography variant="h6">
              {t('common_interactive_map:select_region')}
            </Typography>
            <Box mt={2} mb={3} my={4}>
              {renderRegions()}
            </Box>
          </Box>
          <Box className="showMOBILE">
            <Accordion
              className="IMAccordion"
              expanded={openAccordion}
              onChange={handleAccordionChange}>
              <AccordionSummary
                className="IMNoLeftPadding"
                expandIcon={<ExpandMoreIcon />}
                id="-header">
                <Button className="IMSelectRegionBtn">
                  {t('common_interactive_map:select_region')}
                </Button>
              </AccordionSummary>
              <AccordionDetails className="IMNoLeftPadding">
                {renderRegions()}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box pt={1} pb={4} className="IMSeperator" />
          <Button
            className="IMRegionBtn"
            variant="text"
            component={NavigatorLink}
            href={PATHS.locations()}
            endIcon={
              <ArrowForwardIosIcon className="IMArrowIcon" color="primary" />
            }>
            {t('common_interactive_map:explore_destinations')}
          </Button>
        </Box>
      </Box>
    </StyledBox>
  );
};

export { InteractiveMap };
