import {
  Box, Button, Fade, Grid,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import StyledBox from 'views/rebranded-module/common/components/Logical/MainWidgetV2/MainWidgetV2.styles';
import { WIDGET_TYPES } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/utils/constants';
import { RentalWidgetV2Wrapper } from 'views/rebranded-module/common/components/Logical/RentalWidgetV2Wrapper';
import { TourWidgetV2Wrapper } from 'views/rebranded-module/common/components/Logical/TourWidgetV2Wrapper';
import { WidgetError } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetError/';
import { WidgetWarningV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetWarningV2';

const MainWidgetV2 = (props) => {
  const { className, ...otherProps } = props;
  const { t } = useTranslation('common_main_widget_v2');
  const [widgetType, setWidgetType] = useState(WIDGET_TYPES.RENT);
  // eslint-disable-next-line no-unused-vars
  const [rentalWidgetWarnings, setRentalWidgetWarnings] = useState([]);
  const [widgetErrors, setWidgetErrors] = useState([]);
  const handleWidgetTypeClick = (type) => {
    setWidgetType(type);
  };
  const updateRentalWidgetWarnings = (warnings) => {
    setRentalWidgetWarnings(warnings);
  };
  const handleWidgetOuterError = (errors) => {
    setWidgetErrors(errors);
  };
  return (
    <StyledBox className={className}>
      <Box>
        {widgetErrors.length > 0 && (
          <Box mb={4}>
            <WidgetError
              errors={widgetErrors}
              onClose={() => {
                setWidgetErrors([]);
              }}
            />
          </Box>
        )}
        {rentalWidgetWarnings.length > 0 && (
          <Box mb={4}>
            <WidgetWarningV2
              warnings={rentalWidgetWarnings}
              onClose={() => {
                setRentalWidgetWarnings([]);
              }}
            />
          </Box>
        )}
        <Grid container>
          <Grid item xs={6}>
            <Button
              className={clsx(
                'MWV2BtnType',
                widgetType === WIDGET_TYPES.RENT && 'MV2SelectedBtnType',
              )}
              component="h1"
              onClick={() => {
                handleWidgetTypeClick(WIDGET_TYPES.RENT);
              }}>
              {t('common_main_widget_v2:rent')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={clsx(
                'MWV2BtnType',
                widgetType === WIDGET_TYPES.TOUR && 'MV2SelectedBtnType',
              )}
              onClick={() => {
                handleWidgetTypeClick(WIDGET_TYPES.TOUR);
              }}>
              {t('common_main_widget_v2:tour')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className="MWV2WidgetContainer">
        {widgetType === WIDGET_TYPES.RENT && (
          <Fade in unmountOnExit>
            <Box className="MWV2RentalWidgetContainer">
              <RentalWidgetV2Wrapper
                updateRentalWidgetWarnings={updateRentalWidgetWarnings}
                handleWidgetOuterError={handleWidgetOuterError}
                {...otherProps}
              />
            </Box>
          </Fade>
        )}
        {widgetType === WIDGET_TYPES.TOUR && (
          <Fade in unmountOnExit>
            <Box>
              <TourWidgetV2Wrapper {...otherProps} />
            </Box>
          </Fade>
        )}
      </Box>
    </StyledBox>
  );
};

export { MainWidgetV2 };
