import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.fogWhite,
  overflowX: 'hidden',
  position: 'relative',
  margin: theme.spacing(0.5, -0.5, 0.5, -0.5),
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
  overflow: 'hidden',
  '& .UGCSImgContiner': {
    padding: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(392),
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      height: theme.typography.pxToRem(264.318),
      padding: theme.typography.pxToRem(2.5),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(204),
      padding: theme.typography.pxToRem(2),
    },
    '&.UGCSLongImgContiner': {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: theme.typography.pxToRem(360),
      },
    },
    '&.UGCSImgCustomPosition': {
      [theme.breakpoints.down('lg')]: {
        '& .IBimg': {
          objectPosition: 'right',
        },
      },
    },
    '&.UGCSImgOtherCustomPosition': {
      [theme.breakpoints.down('md')]: {
        '& .IBimg': {
          objectPosition: 'top',
        },
      },
    },
  },
  '& .UGCCustomOrder': {
    [theme.breakpoints.down('lg')]: {
      order: 2,
    },
  },
  '& .UGCSTextContiner': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.typography.pxToRem(44),
    textAlign: 'center',
    padding: theme.spacing(0, 1.875),
    [theme.breakpoints.down('xl')]: {
      gap: theme.typography.pxToRem(28),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3.75, 4),
    },
  },
  '& .UGCSIconBtnContainer': {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
    [theme.breakpoints.down('lg')]: {
      gap: theme.typography.pxToRem(24),
    },
  },
  '& .UGCSIconBtn': {
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(32),
      [theme.breakpoints.down('xl')]: {
        fontSize: theme.typography.pxToRem(25),
      },
    },
  },
}));

export default StyledBox;
