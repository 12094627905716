import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.typography.pxToRem(160),
  [theme.breakpoints.down('xl')]: {
    marginBottom: theme.typography.pxToRem(140),
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.typography.pxToRem(120),
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.typography.pxToRem(100),
  },
  '& .OSTextBox': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(48),
    marginTop: theme.typography.pxToRem(148),
    marginRight: theme.typography.pxToRem(80),
    [theme.breakpoints.down('xl')]: {
      gap: theme.typography.pxToRem(32),
      marginTop: theme.typography.pxToRem(98),
      marginRight: theme.typography.pxToRem(54),
    },
    [theme.breakpoints.down('lg')]: {
      gap: theme.typography.pxToRem(28),
      marginTop: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.typography.pxToRem(26),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.typography.pxToRem(291),
      marginLeft: theme.typography.pxToRem(80),
    },
  },
  '& .OSGrid': {
    [theme.breakpoints.up('xl')]: {
      width: 'clamp(100%, 1760px, 100vw)',
      margin: '0 auto',
    },
    '@media only screen and (min-width: 1760px)': {
      width: 'calc(((100vw - 1760px) / 2) + 1760px)', // 1760 container size
    },
  },
  '& .OSContainer': {
    [theme.breakpoints.down('xl')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.typography.pxToRem(26),
    },
  },
}));
export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '&.react-multi-carousel-list': {
    position: 'relative',
  },
  '& .react-multi-carousel-item': {
    '& .CarouselItem': {
      height: '100%',
      margin: theme.spacing(0, 3, 0.1, 3),
      [theme.breakpoints.down('xl')]: {
        margin: theme.spacing(0, 2, 0.1, 2),
      },
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(0, 1.75, 0.1, 1.75),
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0, 0.1, 0),
      },

      '& .CarouselItem': {
        height: '100%',
        '& > .MuiGrid-container': {
          height: '100%',
        },
        '& > .MuiBox-root ': {
          height: '100%',
        },
      },
    },
    '&:first-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 3, 0.1, 0),
        [theme.breakpoints.down('xl')]: {
          margin: theme.spacing(0, 2, 0.1, 0),
        },
        [theme.breakpoints.down('lg')]: {
          margin: theme.spacing(0, 1.75, 0.1, 0),
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 0, 0.1, 0),
        },
      },
    },
    '&:last-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0, 0.1, 3),
        [theme.breakpoints.down('xl')]: {
          margin: theme.spacing(0, 0, 0.1, 2),
        },
        [theme.breakpoints.down('lg')]: {
          margin: theme.spacing(0, 0, 0.1, 1.75),
        },
        [theme.breakpoints.down('lg')]: {
          margin: theme.spacing(0, 0, 0.1, 0),
        },
      },
    },
    '&:only-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0, 0, 0),
      },
    },
  },

  '& .OSArrow': {
    position: 'absolute',
    top: theme.typography.pxToRem(281), // half of image height
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      top: theme.typography.pxToRem(190),
    },
    [theme.breakpoints.down('lg')]: {
      top: theme.typography.pxToRem(160),
    },
    '& .OSArrowIcon': {
      fontSize: theme.typography.pxToRem(52),
      transition: 'transform 0.3s ease-in-out',
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.pxToRem(37),
      },
    },
    '& .OSDublicateArrow': {
      position: 'relative',
      right: theme.typography.pxToRem(52),
      transition: 'transform 0.3s ease-in-out',
      [theme.breakpoints.down('lg')]: {
        right: theme.typography.pxToRem(39),
      },
    },
    '&.OSArrowLeft': {
      left: theme.typography.pxToRem(0),
      [theme.breakpoints.up('md')]: {
        left: theme.typography.pxToRem(20),
      },
      '& .OSArrowIcon': {
        transform: 'scaleX(-1)', // flip icon
      },
      '&:hover': {
        '& .OSArrowIcon': {
          transform: 'scaleX(-1) translateX(4px)',
        },
      },
    },
    '&.OSArrowRight': {
      right: theme.typography.pxToRem(115),
      [theme.breakpoints.down('xl')]: {
        right: theme.typography.pxToRem(25),
      },
      [theme.breakpoints.down('lg')]: {
        right: theme.typography.pxToRem(35),
      },
      [theme.breakpoints.down('md')]: {
        right: theme.typography.pxToRem(5),
      },
      '&:hover': {
        '& .OSArrowIcon': {
          transform: 'translateX(4px)',
        },
      },
    },
  },
}));
export default StyledBox;
