import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export const useGetRegions = () => {
  const { t } = useTranslation();
  const regions = useMemo(
    () => [
      {
        name: t('common_interactive_map:united_states'),
        mapInfo: {
          desktop: {
            center: { lat: 37.69784245847987, lng: -110.51828196324028 },
            zoom: 5,
          },
          mobile: {
            center: { lat: 20.61645543851219, lng: -109.70212358764279 },
            zoom: 4,
          },
        },
      },
      {
        name: t('common_interactive_map:north_america'),
        mapInfo: {
          desktop: {
            center: { lat: 46.45830533499995, lng: -122.17493539520679 },
            zoom: 4,
          },
          mobile: {
            center: { lat: 17.12411756028314, lng: -103.11032671264279 },
            zoom: 3,
          },
        },
      },
      {
        name: t('common_interactive_map:south_america'),

        mapInfo: {
          desktop: {
            center: { lat: -24.75243660853141, lng: -66.76662703556686 },
            zoom: 5,
          },
          mobile: {
            center: { lat: -35.773529423746645, lng: -54.004788813204755 },
            zoom: 4,
          },
        },
      },
      {
        name: t('common_interactive_map:europe'),
        center: { lat: 48.402127074950045, lng: -360.57088341336924 },
        zoom: 5,

        mapInfo: {
          desktop: {
            center: { lat: 48.402127074950045, lng: -360.57088341336924 },
            zoom: 5,
          },
          mobile: {
            center: { lat: 31.58409095234937, lng: 8.207199733752873 },
            zoom: 3,
          },
        },
      },
      {
        name: t('common_interactive_map:africa'),

        mapInfo: {
          desktop: {
            center: { lat: 1.569173517998351, lng: 7.768616868388023 },
            zoom: 4,
          },
          mobile: {
            center: { lat: -39.88611565425956, lng: 15.253904483187597 },
            zoom: 3,
          },
        },
      },
      {
        name: t('common_interactive_map:oceania'),

        mapInfo: {
          desktop: {
            center: { lat: -32.18135011439897, lng: 145.01882758083434 },
            zoom: 5,
          },
          mobile: {
            center: { lat: -57.874752751329424, lng: 146.6966685725206 },
            zoom: 3,
          },
        },
      },
      {
        name: t('common_interactive_map:asia'),

        mapInfo: {
          desktop: {
            center: { lat: 36.75057843108368, lng: -239.71147619811978 },
            zoom: 5,
          },
          mobile: {
            center: { lat: 24.78245398133751, lng: 132.88825634030337 },
            zoom: 4,
          },
        },
      },
    ],
    [],
  );
  return regions;
};
