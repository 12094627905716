import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.fogWhite,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  '& .HVBGImg': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    '& .IBimg': {
      opacity: 0.06,
    },
  },
  '& .HVPageContent': {
    position: 'relative',
    zIndex: 1,
  },
}));

export default StyledBox;
