import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import StyledBox from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetError/WidgetError.styles';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';

const WidgetError = (props) => {
  const { errors = [], onClose } = props;
  const { t } = useTranslation('common_main_widget_v2');
  if (errors?.length === 0) {
    return null;
  }
  return (
    <StyledBox className="WEContainer">
      <ErrorIcon color="error" />
      <Box pl={1.2} flex={1}>
        <Typography variant="body" component="p" className="WEErrorText">
          {t('common_main_widget_v2:error')}
        </Typography>
        {errors.map((item) => (
          <Typography
            key={item}
            variant="body2"
            component="p"
            className="WEErrorItem">
            {item}
          </Typography>
        ))}
      </Box>
      <Box>
        <IconButton onClick={onClose} className="WECloseIcon">
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledBox>
  );
};

export { WidgetError };
