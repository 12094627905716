import React, { useRef, useState } from 'react';
import CalendarIcon from 'views/common/components/UI/Icons/CalendarIcon';
import StyledIconedBox, {
  StyledLocalizedDatePicker,
  StyledPopper,
  StyledDialog,
} from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/MainWidgetDatePickerV2/MainWidgetDatePickerV2.styles';

const MainWidgetDatePickerV2 = (props) => {
  const {
    error,
    helperText,
    className,
    id,
    onChange,
    disabled,
    resetOpenWidgetDateTimeField = () => {},
    ...otherProps
  } = props;
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  inputRef?.current?.blur();

  return (
    <StyledIconedBox
      error={error}
      className={className}
      icon={<CalendarIcon />}>
      <StyledLocalizedDatePicker
        open={open}
        onOpen={() => {
          if (!disabled) {
            setOpen(true);
          }
        }}
        format="MMM D, YYYY"
        onChange={(...args) => {
          if (document.getElementsByClassName('MuiPickersDay-root')?.length) {
            onChange(...args);
            setOpen(false);
            resetOpenWidgetDateTimeField();
          }
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: 'filled',
            helperText,
            InputLabelProps: { shrink: true },
            error,
            id,
            onClick: () => {
              if (!disabled) {
                setOpen(true);
              }
            },
          },
          popper: {
            placement: 'bottom-start',
            component: StyledPopper,
            'data-testid': otherProps.label,
          },
          dialog: {
            'data-testid': otherProps.label,
            sx: StyledDialog,
          },
        }}
        onClose={() => {
          setOpen(false);
          resetOpenWidgetDateTimeField();
        }}
        {...otherProps}
      />
    </StyledIconedBox>
  );
};

export { MainWidgetDatePickerV2 };
