import { SvgIcon } from '@mui/material';
import * as React from 'react';

const RidersIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={26}
    viewBox="0 0 32 26"
    fill="none"
    {...props}>
    <path
      d="M9.29463 7.5165C9.32119 7.62587 9.39327 7.72204 9.49759 7.77295L10.6091 8.32546C10.7571 8.399 10.9089 8.465 11.0625 8.52346C10.3322 9.88871 10.3474 11.5613 11.1365 12.9171L11.9616 14.3333C12.6065 15.4402 12.4206 16.8432 11.512 17.7483C10.979 18.2782 10.2431 18.5799 9.48621 18.5761L4.3704 18.5497C1.62186 16.7564 -0.011322 13.7468 5.90936e-05 10.4752C0.00954335 7.89552 1.02246 5.47994 2.85292 3.67533C4.672 1.88014 7.09238 0.893921 9.6702 0.893921H9.71952C11.7492 0.903349 13.9002 1.66517 15.632 2.96442C15.5732 2.98139 15.5144 3.00213 15.4556 3.02476L10.6205 4.94629C9.60002 5.35171 9.03097 6.45673 9.29273 7.5165H9.29463Z"
      fill="black"
    />
    <path
      d="M14.238 15.4572C14.6496 15.4572 15.0403 15.3007 15.3362 15.0178C15.8161 14.5596 15.9565 13.8468 15.6853 13.2434L15.5221 12.8757C14.955 11.6066 14.6666 10.2583 14.6666 8.86854V8.63849L13.3825 8.79877C13.1814 8.82329 12.9784 8.83649 12.7774 8.83649C12.4568 8.83649 12.14 8.80443 11.8271 8.7422C11.1385 9.90002 11.1252 11.3596 11.8081 12.5325L12.6332 13.9486C12.9083 14.4219 13.0657 14.9386 13.1074 15.4591H14.2399L14.238 15.4572Z"
      fill="black"
    />
    <path
      d="M18.3996 5.23101C18.153 5.13106 17.8988 5.03867 17.6408 4.94438C17.3771 4.84632 17.3221 4.64078 17.2766 4.38244L17.269 4.36547C17.0148 3.77525 16.3453 3.49994 15.744 3.73753L10.9089 5.66095C10.3816 5.87026 10.0401 6.3662 10.006 6.90739C9.98891 7.18836 10.5656 7.44293 10.7533 7.54099C11.533 7.95019 12.4036 8.1463 13.2856 8.03693L20.6985 7.11671C20.6758 7.11859 20.249 6.46425 20.1902 6.39825C19.6856 5.82689 19.0653 5.50443 18.3977 5.23478L18.3996 5.23101Z"
      fill="black"
    />
    <path
      d="M20.596 14.5615C20.6226 14.6709 20.6947 14.7671 20.799 14.818L21.9105 15.3705C22.0585 15.444 22.2102 15.51 22.3639 15.5685C21.6336 16.9338 21.6488 18.6064 22.4379 19.9622L23.263 21.3783C23.9079 22.4853 23.722 23.8882 22.8134 24.7933C22.2804 25.3232 21.5444 25.6249 20.7876 25.6212L15.6718 25.5948C12.9233 23.8015 11.2901 20.7919 11.3015 17.5202C11.3109 14.9406 12.3238 12.525 14.1543 10.7204C15.9734 8.92518 18.3938 7.93896 20.9716 7.93896H21.0209C23.0505 7.94839 25.2016 8.71022 26.9334 10.0095C26.8746 10.0264 26.8158 10.0472 26.757 10.0698L21.9219 11.9913C20.9014 12.3968 20.3324 13.5018 20.5941 14.5615H20.596Z"
      fill="black"
    />
    <path
      d="M25.5411 22.5021C25.9527 22.5021 26.3434 22.3456 26.6393 22.0627C27.1192 21.6045 27.2596 20.8917 26.9884 20.2883L26.8252 19.9206C26.2581 18.6515 25.9698 17.3032 25.9698 15.9135V15.6834L24.6856 15.8437C24.4845 15.8682 24.2816 15.8814 24.0805 15.8814C23.7599 15.8814 23.4431 15.8494 23.1302 15.7871C22.4416 16.9449 22.4283 18.4045 23.1112 19.5774L23.9363 20.9935C24.2114 21.4668 24.3688 21.9835 24.4105 22.504H25.543L25.5411 22.5021Z"
      fill="black"
    />
    <path
      d="M29.7011 12.2779C29.4545 12.178 29.2003 12.0856 28.9423 11.9913C28.6787 11.8933 28.6237 11.6877 28.5781 11.4294L28.5705 11.4124C28.3164 10.8222 27.6468 10.5469 27.0455 10.7845L22.2104 12.7079C21.6831 12.9172 21.3416 13.4131 21.3075 13.9543C21.2904 14.2353 21.8671 14.4899 22.0549 14.5879C22.8345 14.9971 23.7051 15.1932 24.5872 15.0839L32 14.1636C31.9773 14.1655 31.5505 13.5112 31.4917 13.4452C30.9871 12.8738 30.3669 12.5514 29.6992 12.2817L29.7011 12.2779Z"
      fill="black"
    />
  </SvgIcon>
);

export default RidersIcon;
