import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.secondary.main,

  '& .HSWidgetContainer': {
    position: 'absolute',
    bottom: '8%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'max(70% , 1000px)',
    zIndex: 10,
    [theme.breakpoints.down('lg')]: {
      width: 'max(90% , 600px)',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '10%',
      width: 'max(90% , 340px)',
      display: 'none',
    },
  },

  '& .HSVideoWrapper': {
    position: 'relative',
    display: 'flex',
    zIndex: 7,
    width: '100%',
    '&::before,&::after': {
      content: '""',
      width: '100%',
      position: 'absolute',
      right: 0,
      left: 0,
    },
    '&::after': {
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
      bottom: -1,
      height: 400,
    },
    '&::before': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)',
      top: 0,
      height: 200,
    },
    '& .HSVideo': {
      width: '100%',
      objectFit: 'cover',
      height: 'calc(100vh - 140px)',
      [theme.breakpoints.down('lg')]: {
        height: 'calc(100vh - 44px)',
      },
    },
  },

  '& .HSTextContent': {
    color: theme.palette.common.fogWhite,
    textAlign: 'center',
    position: 'relative',
    zIndex: 6,
    '& .HSTextTitle': {
      lineHeight: '110%',
      color: theme.palette.common.fogWhite,
      [theme.breakpoints.down('xl')]: {
        fontSize: theme.typography.pxToRem(34),
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.pxToRem(28),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    '& .HSText': {
      lineHeight: '150%',
      color: theme.palette.common.fogWhite,
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: 0,
    },
    '& .HSTextContentContainer': {
      maxWidth: theme.spacing(140),
    },
  },

  '&::after': {
    backgroundImage: "url('/imgs/Rebranding/HomePage/Asphalt.png')",
    bottom: 0,
    height: 700,
    content: '""',
    width: '100%',
    position: 'absolute',
    right: 0,
    left: 0,
  },
  '& .HSFixedButton': {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    zIndex: 99,
    borderRadius: 0,
    height: theme.spacing(6.25),
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
}));

export default StyledBox;
