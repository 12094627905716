import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, IconButton, Typography } from '@mui/material';
import StyledBox from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetWarningV2/WidgetWarningV2.styles';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';

const WidgetWarningV2 = (props) => {
  const { warnings = [], onClose } = props;
  const { t } = useTranslation();
  return (
    <StyledBox>
      <ErrorOutlineIcon />
      <Box pl={1.2} flex={1}>
        <Typography variant="body" component="p" className="WWWarningText">
          {t('common_main_widget_v2:warning')}
        </Typography>
        {warnings.map((item) => (
          <Typography
            key={item}
            variant="body"
            component="p"
            className="MWWarningItemt">
            {item}
          </Typography>
        ))}
      </Box>
      <Box>
        <IconButton onClick={onClose} className="WECloseIcon">
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledBox>
  );
};

export { WidgetWarningV2 };
