import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99999 8.81839L14 4.69995L14 6.39323L6.99999 10.5333L1.99531e-05 6.39323L9.53674e-07 4.69995L6.99999 8.81839Z"
      fill="black"
    />
  </SvgIcon>
);

export default ArrowIcon;
