import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import StyledBox from 'views/rebranded-module/HomeViewV2/components/UI/OfferCard/OfferCard.styles';
import { useTranslation } from 'next-i18next';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import { Typography700, ImgBox } from 'views/common/components';
import useMultiSizedImage from 'views/common/hooks/useMutiSizeImage';
import { OFFERS_TYPE } from 'views/common/utils/constants';
import PATHS from 'lib/navigation/paths';

const OfferCard = (props) => {
  const { t } = useTranslation('fe_er_home_page_V2');
  const {
    imageAlt, subTitle, desciption, image, type, slug,
  } = props;
  const sizedImg = useMultiSizedImage(image);

  const calculateActionAttributes = useMemo(() => {
    switch (type) {
      case OFFERS_TYPE.DEAL:
        return {
          actionHref: PATHS.motorcycleDeals.slug(slug, {
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page_V2:book_today'),
        };
      case OFFERS_TYPE.MEMBERSHIP:
        return {
          actionHref: PATHS.motorcycleClub.index({
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page_V2:learn_more'),
        };
      case OFFERS_TYPE.BLOG:
        return {
          actionHref: PATHS.motorcycleTouring.slug(slug, {
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page_V2:learn_more'),
        };
      default:
        return {
          actionHref: slug || '',
          actionLabel: null,
        };
    }
  }, [type, slug]);
  return (
    <StyledBox className="OCCard">
      <NavigatorLink href={calculateActionAttributes.actionHref}>
        <Box className="OCImg">
          <ImgBox
            image={{
              src: sizedImg,
              alt: imageAlt,
            }}
            imgHeight="100%"
            imgWidth="100%"
          />
        </Box>
      </NavigatorLink>
      <Box className="OCContent">
        <Typography700
          variant="bodyEyebrow"
          component="p"
          className="OCCaption">
          {subTitle}
        </Typography700>
        <Typography700
          variant="h5"
          component="p"
          className="OCDesc"
          dangerouslySetInnerHTML={{
            __html: desciption,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          component={NavigatorLink}
          className="OSBtn"
          href={calculateActionAttributes.actionHref}>
          {calculateActionAttributes.actionLabel}
        </Button>
      </Box>
    </StyledBox>
  );
};
export { OfferCard };
