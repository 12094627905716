import { SvgIcon } from '@mui/material';
import * as React from 'react';

const LocationIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    {...props}>
    <path
      d="M16.2285 0.5C10.0436 0.5 5.02856 5.4764 5.02856 11.6138C5.02856 14.4204 6.10201 17.0946 7.52756 19.2784C9.96407 23.0093 16.2285 32.5 16.2285 32.5C16.2285 32.5 22.4931 23.0093 24.9296 19.2784C26.3551 17.0946 27.4286 14.4204 27.4286 11.6138C27.4286 5.4764 22.4135 0.5 16.2285 0.5ZM16.2285 17.8272C12.7697 17.8272 9.9669 15.046 9.9669 11.6138C9.9669 8.18158 12.7697 5.40032 16.2285 5.40032C19.6874 5.40032 22.4902 8.18158 22.4902 11.6138C22.4902 15.046 19.6874 17.8272 16.2285 17.8272Z"
      fill="black"
    />
  </SvgIcon>
);

export default LocationIcon;
