import { SvgIcon } from '@mui/material';
import * as React from 'react';

const ToursNameIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    {...props}>
    <path
      d="M5.72413 16.0279C5.55942 16.1 5.43227 16.2385 5.37448 16.4058L0.36671 31.1816C0.201999 31.6691 0.673014 32.1336 1.17581 31.9836L7.67176 30.1287L10.3331 14.0085L5.72413 16.0308V16.0279Z"
      fill="black"
    />
    <path
      d="M7.67212 30.1258L15.3181 32.5V16.2529L10.3335 14.0085L7.67212 30.1258Z"
      fill="black"
    />
    <path
      d="M20.083 19.351L17.4476 15.272L15.2688 16.2528V32.4998L22.9119 30.1257L21.2908 20.3146C20.8025 20.1386 20.3748 19.8011 20.083 19.351Z"
      fill="black"
    />
    <path
      d="M31.7398 31.1901L26.9227 16.5931C26.8678 16.4258 26.7465 16.2902 26.5846 16.2181L26.2812 16.0825L24.1718 19.351C23.9146 19.7462 23.5534 20.0549 23.1431 20.2424L24.7642 30.0564L30.9625 31.9805C31.4479 32.1305 31.9016 31.6718 31.7427 31.1901H31.7398Z"
      fill="black"
    />
    <path
      d="M28.9281 7.11386C28.8386 3.61463 26.0125 0.703888 22.5131 0.510608C18.5774 0.29425 15.3179 3.41557 15.3179 7.29271C15.3179 8.5274 15.6473 9.68419 16.2252 10.6766L21.3168 18.5578C21.6953 19.1434 22.5565 19.1434 22.935 18.5578L28.0237 10.6766C28.6305 9.63515 28.9628 8.41489 28.9281 7.11386ZM22.123 10.6073C20.2852 10.6073 18.7941 9.11877 18.7941 7.28406C18.7941 5.44934 20.2823 3.95791 22.123 3.95791C23.9637 3.95791 25.4519 5.44934 25.4519 7.28406C25.4519 9.11877 23.9637 10.6073 22.123 10.6073Z"
      fill="black"
    />
  </SvgIcon>
);

export default ToursNameIcon;
