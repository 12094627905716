import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.fogWhite,
  position: 'relative',
  '& .ISBGImg': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
  },

  '& .ISLefSide': {
    position: 'relative',
    width: '100%',
    minHeight: theme.spacing(152.5),
    [theme.breakpoints.down('xl')]: {
      minHeight: theme.spacing(115.125),
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: 'unset',
      height: theme.spacing(90.25),
      order: 2,
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(51.5),
    },
  },
  '& .ISIntroImg': {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '81%',
    },
    [theme.breakpoints.down('md')]: {
      width: '89%',
    },
  },

  '& .ISSubImg': {
    border: `4px solid ${theme.palette.primary.main}`,
    position: 'relative',
    zIndex: 1,
    width: theme.typography.pxToRem(598),
    marginBottom: theme.typography.pxToRem(117),
    aspectRatio: '1.47 / 1',
    left: theme.typography.pxToRem(-200),

    [theme.breakpoints.down('xl')]: {
      left: theme.typography.pxToRem(-150),
      width: theme.typography.pxToRem(372),
      marginBottom: theme.typography.pxToRem(90),
    },
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      left: 'unset',
      marginBottom: 0,
      right: theme.typography.pxToRem(0),
      width: '43%',
      bottom: theme.typography.pxToRem(100),
    },
    [theme.breakpoints.down('md')]: {
      bottom: theme.typography.pxToRem(60),
      width: '59%',
    },
  },
  '& .ISTextBox': {
    color: theme.palette.common.black,
    marginTop: theme.typography.pxToRem(165),
    marginBottom: theme.typography.pxToRem(115),
    maxWidth: theme.spacing(70.375),
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.typography.pxToRem(120),
      marginBottom: theme.typography.pxToRem(120),
      maxWidth: theme.spacing(53.375),
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(12.5),
      maxWidth: 'unset',
      marginBottom: theme.typography.pxToRem(100),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
  },
  '& .ISTextTitle': {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.typography.pxToRem(26.76),
    },
  },
  '& .ISTextBtn': {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export default StyledBox;
