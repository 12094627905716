import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FillYoutubeIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none">
    <path
      d="M18.5455 16.8066L14.1407 18.9824C13.9679 19.0767 13.3818 18.95 13.3818 18.7532V14.2885C13.3818 14.089 13.9733 13.965 14.1461 14.062L18.3618 16.3537C18.5374 16.4534 18.7237 16.7069 18.5455 16.8066Z"
      fill="black"
    />
    <path
      d="M16.0014 0.548828C7.16482 0.548828 0 7.70155 0 16.5205C0 25.3394 7.16482 32.4948 16.0014 32.4948C24.8379 32.4948 32 25.3421 32 16.5205C32 7.69885 24.8379 0.548828 16.0014 0.548828ZM24.5678 18.7609C24.5678 20.88 22.8475 22.5974 20.7248 22.5974H10.8998C8.77981 22.5974 7.05949 20.88 7.05949 18.7609V14.1964C7.05949 12.08 8.77981 10.3626 10.8998 10.3626H20.7248C22.8475 10.3626 24.5678 12.08 24.5678 14.1964V18.7609Z"
      fill="black"
    />
  </SvgIcon>
);

export default FillYoutubeIcon;
