import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FillInstegramIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      d="M16.0027 11.7076C13.6313 11.7076 11.7056 13.6327 11.7056 15.9974C11.7056 18.3621 13.634 20.2872 16.0027 20.2872C18.3714 20.2872 20.2998 18.3621 20.2998 15.9974C20.2998 13.6327 18.3714 11.7076 16.0027 11.7076ZM16.0027 11.7076C13.6313 11.7076 11.7056 13.6327 11.7056 15.9974C11.7056 18.3621 13.634 20.2872 16.0027 20.2872C18.3714 20.2872 20.2998 18.3621 20.2998 15.9974C20.2998 13.6327 18.3714 11.7076 16.0027 11.7076ZM16.0027 0.0244141C7.16543 0.0244141 0 7.17774 0 15.9974C0 20.4113 1.79069 24.4045 4.68603 27.295C7.58137 30.1854 11.5841 31.9731 16 31.9731C24.8346 31.9731 32 24.8197 32 16.0001C32 7.17774 24.8346 0.0244141 16 0.0244141H16.0027ZM26.3147 21.2687C26.3147 24.0378 24.0567 26.2946 21.2802 26.2946H10.7225C7.94868 26.2946 5.68805 24.0378 5.68805 21.2687V10.7288C5.68805 7.95967 7.94868 5.70285 10.7225 5.70285H21.2802C24.0567 5.70285 26.3147 7.95967 26.3147 10.7288V21.2687ZM21.2802 7.08337H10.7225C8.71033 7.08337 7.07091 8.71734 7.07091 10.7288V21.2687C7.07091 23.2774 8.70763 24.9141 10.7225 24.9141H21.2802C23.2924 24.9141 24.9318 23.2801 24.9318 21.2687V10.7288C24.9318 8.72003 23.2951 7.08337 21.2802 7.08337ZM16.0027 21.6704C12.8697 21.6704 10.3201 19.1251 10.3201 15.9974C10.3201 12.8697 12.8697 10.327 16.0027 10.327C19.1357 10.327 21.6826 12.8724 21.6826 15.9974C21.6826 19.1224 19.133 21.6704 16.0027 21.6704ZM22.1526 11.3732C21.5503 11.3732 21.0642 10.8852 21.0642 10.2866C21.0642 9.68801 21.553 9.19998 22.1526 9.19998C22.7522 9.19998 23.2411 9.68801 23.2411 10.2866C23.2411 10.8852 22.7522 11.3732 22.1526 11.3732ZM16.0027 11.7076C13.6313 11.7076 11.7056 13.6327 11.7056 15.9974C11.7056 18.3621 13.634 20.2872 16.0027 20.2872C18.3714 20.2872 20.2998 18.3621 20.2998 15.9974C20.2998 13.6327 18.3714 11.7076 16.0027 11.7076Z"
      fill="black"
    />
  </SvgIcon>
);

export default FillInstegramIcon;
