import axios, { API } from 'lib/config/axios';
import PATHS from 'lib/navigation/paths';
import { TOUR_TYPES, TOUR_WIDGET_FIELDS } from 'lib/utils/mainWidgetConstants';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/features/ui-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { TourWidgetV2 } from 'views/rebranded-module/common/components/Logical/TourWidgetV2';
import { WL_SS_KEY } from 'views/common/components/Logical/MainWidgetWrapper/utils/constants';

const initTourWidgetData = {
  selectedCountry: null,
  selectedCity: null,
  selectedTour: null,
  selectedTourType: { id: null },
  countries: [],
  cities: [],
  tours: [],
};

const TourWidgetV2Wrapper = (props) => {
  const {
    handleTourWidgetSearchClick,
    whiteLabelWidget = false,
    reservationData = null,
  } = props;

  const { t } = useTranslation('common_tour_widget_v2');
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const [tourWidgetData, setTourWidgetData] = useState(initTourWidgetData);

  const tourTypesList = useMemo(
    () => [
      { id: null, name: t('common_tour_widget_v2:all_moto_tours') },
      {
        id: TOUR_TYPES.GUIDED,
        name: t('common_tour_widget_v2:guided_moto_tours'),
      },
      {
        id: TOUR_TYPES.SELF,
        name: t('common_tour_widget_v2:self_drive_motorcycle_tours'),
      },
    ],
    [],
  );

  const selectedOptions = useMemo(
    () => ({
      country: tourWidgetData.selectedCountry,
      city: tourWidgetData.selectedCity,
      tour: tourWidgetData.selectedTour,
      tour_type: tourWidgetData.selectedTourType,
    }),
    [tourWidgetData],
  );

  const getTourWidgetData = (queryParams) => {
    let params = queryParams;
    if (!params) {
      params = {
        country_id: tourWidgetData.selectedCountry?.id,
        city_id: tourWidgetData.selectedCity?.id,
        tour_id: tourWidgetData.selectedTour?.id,
        tour_type_id: tourWidgetData.selectedTour?.id,
      };
    }
    dispatch(setLoading(true));
    return axios
      .get(API.tours.search_widget, {
        params: {
          ...params,
          referral_code: reservationData?.referral_code || null,
        },
      })
      .then((res) => {
        setTourWidgetData((prevData) => ({
          ...prevData,
          ...res.data,
        }));
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const adjustTourWidgetData = (fieldsToChange) => {
    if (Object.keys(fieldsToChange).length > 0) {
      setTourWidgetData((prevData) => ({
        ...prevData,
        ...fieldsToChange,
      }));
    }
  };

  const getWidgetParams = (fieldType, newValue) => {
    let fieldsToChange = {};
    switch (fieldType) {
      case TOUR_WIDGET_FIELDS.TOUR_TYPE:
        fieldsToChange = {
          selectedTourType: newValue,
          selectedCountry: null,
          selectedCity: null,
          selectedTour: null,
        };
        adjustTourWidgetData(fieldsToChange);
        return {
          tour_type_id: newValue != null ? newValue.id : '',
          country_id:
            fieldsToChange.selectedCountry === null
              ? null
              : tourWidgetData.selectedCountry?.id,
          city_id:
            fieldsToChange.selectedCity === null
              ? null
              : tourWidgetData.selectedCity?.id,
          tour_id:
            fieldsToChange.selectedTour === null
              ? null
              : tourWidgetData.selectedTour?.id,
        };
      case TOUR_WIDGET_FIELDS.COUNTRY:
        fieldsToChange.selectedCountry = newValue;
        if (tourWidgetData.selectedCity) {
          if (
            newValue
            && tourWidgetData.selectedCity.country_id !== newValue.id
          ) {
            fieldsToChange.selectedCity = null;
          }
        }
        if (tourWidgetData.selectedTour) {
          if (
            newValue
            && tourWidgetData.selectedTour.country_id !== newValue.id
          ) {
            fieldsToChange.selectedTour = null;
          }
        }
        adjustTourWidgetData(fieldsToChange);
        return {
          tour_type_id: tourWidgetData.selectedTourType?.id,
          country_id: newValue != null ? newValue.id : '',
          city_id:
            fieldsToChange.selectedCity === null
              ? null
              : tourWidgetData.selectedCity?.id,
          tour_id:
            fieldsToChange.selectedTour === null
              ? null
              : tourWidgetData.selectedTour?.id,
        };
      case TOUR_WIDGET_FIELDS.CITY:
        fieldsToChange.selectedCity = newValue;
        if (tourWidgetData.selectedTour) {
          if (newValue && tourWidgetData.selectedTour.city_id !== newValue.id) {
            fieldsToChange.selectedTour = null;
          }
        }
        adjustTourWidgetData(fieldsToChange);
        return {
          country_id: tourWidgetData.selectedCountry?.id,
          tour_type_id: tourWidgetData.selectedTourType?.id,
          city_id: newValue != null ? newValue.id : '',
          tour_id:
            fieldsToChange.selectedTour === null
              ? null
              : tourWidgetData.selectedTour?.id,
        };
      case TOUR_WIDGET_FIELDS.TOUR:
        fieldsToChange.selectedTour = newValue;
        adjustTourWidgetData(fieldsToChange);
        return {
          country_id: tourWidgetData.selectedCountry?.id,
          city_id: tourWidgetData.selectedCity?.id,
          tour_type_id: tourWidgetData.selectedTourType?.id,
          tour_id: newValue != null ? newValue.id : '',
        };
      default:
        return {};
    }
  };

  const handleSelectInputChange = async (fieldType, e, newValue) => {
    const params = getWidgetParams(fieldType, newValue);
    await getTourWidgetData(params);
  };

  const getRedirectionPath = () => {
    const tourTypeSlug = tourWidgetData.tour_type?.slug;
    if (selectedOptions.tour?.id) {
      const tourSlug = tourWidgetData.selectedTour.slug;
      return PATHS.tour.index(tourTypeSlug, tourSlug);
    }
    if (selectedOptions.city?.id) {
      const { selectedCity } = tourWidgetData;
      if (tourTypeSlug) {
        return PATHS.tour.index(tourTypeSlug, selectedCity.slug);
      }
      return PATHS.filteredMotorcycleTours(selectedCity.slug);
    }
    if (selectedOptions.country?.id) {
      const { selectedCountry } = tourWidgetData;

      if (tourTypeSlug) {
        return PATHS.tour.index(tourTypeSlug, selectedCountry.slug);
      }
      return PATHS.filteredMotorcycleTours(selectedCountry.slug);
    }
    if (tourTypeSlug) {
      return PATHS.tour.tourType(tourTypeSlug);
    }
    return PATHS.motorcycleTours.index({ q: ' ' });
  };

  const getReferralCode = () => appNavigator.query.ReferralCode || sessionStorage.getItem(WL_SS_KEY);

  const handleSearchButtonClick = () => {
    if (typeof handleTourWidgetSearchClick === 'function') {
      handleTourWidgetSearchClick(selectedOptions);
      return;
    }
    const redirectionPath = getRedirectionPath();
    if (whiteLabelWidget) {
      window.open(
        appNavigator.getUrl(
          undefined,
          getReferralCode()
            ? `${redirectionPath.url}?referral_code=${getReferralCode()}`
            : redirectionPath.url,
        ),
        '_blank',
        'noopener,noreferrer',
      );
    } else {
      appNavigator.push(redirectionPath);
    }
  };

  useEffect(() => {
    getTourWidgetData();
  }, []);

  return (
    <TourWidgetV2
      tourTypesList={tourTypesList}
      countriesList={tourWidgetData.countries}
      toursList={tourWidgetData.tours}
      handleSelectInputChange={handleSelectInputChange}
      selectedOptions={selectedOptions}
      handleSearchButtonClick={handleSearchButtonClick}
      {...props}
    />
  );
};

export { TourWidgetV2Wrapper };
