import React, { useMemo, useRef, useState } from 'react';
import { IconedBox } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/IconecBox';
import { WidgetTextFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2';
import { Autocomplete, Box, Typography } from '@mui/material';
import LocationIcon from 'views/common/components/UI/Icons/LocationIcon';
import { useTranslation } from 'next-i18next';
import { ERRORS_PREFIX } from 'lib/utils/mainWidgetConstants';
import StyledBox, {
  StyledPopper,
} from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetLocationFieldV2/WidgetLocationFieldV2.styles';
import ArrowIcon from 'views/common/components/UI/Icons/ArrowIcon';

const WidgetLocationFieldV2 = (props) => {
  const {
    selectedLocationId,
    fieldName,
    locationsList = [],
    label,
    placeholder,
    handleInputChange,
    handleLocationChange,
    handleRenderAllWidget,
    loading,
    errors,
    disabled,
    className,
    fieldType,
  } = props;

  const { t } = useTranslation('common_rental_widget_v2');

  const [isFocused, setIsFocused] = useState('');
  const autoCompleteInputRef = useRef();

  const selectedLocation = useMemo(
    () => locationsList.find(
      (location) => location.id && location.id === selectedLocationId,
    ) || null,
    [selectedLocationId, locationsList],
  );

  const placeHolder = useMemo(() => {
    if (isFocused && selectedLocation) {
      return `${selectedLocation.display_name}, ${selectedLocation.region_name}`;
    }
    return placeholder;
  }, [selectedLocation, isFocused, placeholder]);

  const fieldValue = useMemo(() => {
    if (isFocused) return null;
    return selectedLocation;
  }, [isFocused, selectedLocation]);

  return (
    <StyledBox className={className}>
      <IconedBox
        error={!!errors[`${ERRORS_PREFIX}${fieldName}`]}
        icon={<LocationIcon />}>
        <Autocomplete
          PopperComponent={StyledPopper}
          value={fieldValue}
          options={loading ? [] : locationsList}
          filterOptions={(x) => x}
          getOptionLabel={(option) => `${option.display_name}, ${option.region_name}`}
          id={`${ERRORS_PREFIX}${fieldName}`}
          error={!!errors[`${ERRORS_PREFIX}${fieldName}`]}
          //  helperText={errors[`${ERRORS_PREFIX}${fieldName}`] || ''}
          className="WidgetInput"
          autoComplete
          disableClearable
          includeInputInLis
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          popupIcon={<ArrowIcon />}
          onInputChange={(e, value, reason) => {
            if (reason !== 'reset') {
              handleInputChange(fieldType, value);
            }
          }}
          disabled={disabled}
          onChange={(e, value) => {
            setIsFocused(true);
            handleLocationChange(fieldName, value?.id);
            autoCompleteInputRef.current.blur();
          }}
          onOpen={() => handleRenderAllWidget?.(true)}
          loading={loading}
          renderOption={(params, option) => (
            <li {...params}>
              <Box>
                <Typography
                  className="WLFOptionText"
                  dangerouslySetInnerHTML={{
                    __html: `${option.display_name}, <b>${option.region_name}</b>`,
                  }}
                />
                {option.distance >= 0 && (
                  <Typography>
                    {`${option.distance} ${t(
                      'common_rental_widget_v2:miles_away',
                    )} ${option.formattedAddress}`}
                  </Typography>
                )}
              </Box>
            </li>
          )}
          renderInput={(params) => (
            <WidgetTextFieldV2
              {...params}
              inputRef={autoCompleteInputRef}
              placeholder={placeHolder}
              label={label}
              isFocused={isFocused}
              variant="filled"
              error={!!errors[`${ERRORS_PREFIX}${fieldName}`]}
            />
          )}
        />
      </IconedBox>
    </StyledBox>
  );
};

export { WidgetLocationFieldV2 };
