import { alpha, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MWV2BtnType': {
    width: '100%',
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    borderRadius: 'unset',
    color: theme.palette.grey.concrete,
    borderTop: '4px solid transparent',
    fontFamily: theme.typography.fontFamilySecondary,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(2),
    lineHeight: '110%',
    transition: 'all ease 0.5s',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.palette.grey.concrete}`,
      borderTop: 0,
    },

    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.6),
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.light,
      },
    },
    '&.MV2SelectedBtnType': {
      backgroundColor: alpha(theme.palette.common.fogWhite, 0.9),
      color: theme.palette.common.black,
      borderTopColor: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
        borderTopColor: 'unset',
        borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
      },
    },
  },
  '& .MWV2WidgetContainer': {
    backgroundColor: alpha(theme.palette.common.fogWhite, 0.9),
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      marginTop: theme.spacing(3),
    },
  },
  '& .MWV2RentalWidgetContainer > .MuiBox-root > .MuiBox-root': {
    backgroundColor: 'unset',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export default StyledBox;
