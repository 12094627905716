import React from 'react';
import {
  Box, Skeleton, styled, Grid,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  padding: theme.spacing(6, 0),
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.grey[700],
  },
}));
const UserGenerationContentSkeleton = () => (
  <StyledBox>
    <Grid container spacing={1}>
      <Grid item lg={3} md={4} sm={12}>
        <Skeleton variant="rectangular" width="100%" height={808} />
      </Grid>
      <Grid item lg={9} md={8} sm={12}>
        <Box mb={1}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={6} sm={12}>
              <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
            <Grid item lg={3} md={0} sm={0} className="showDESKTOP">
              <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={12}>
            <Skeleton variant="rectangular" width="100%" height={400} />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <Skeleton variant="rectangular" width="100%" height={400} />
          </Grid>
          <Grid item lg={6} md={0} sm={0} className="showDESKTOP">
            <Skeleton variant="rectangular" width="100%" height={400} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </StyledBox>
);
export { UserGenerationContentSkeleton };
