import React from 'react';
import {
  Box, Skeleton, styled, Grid, Container,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.grey[700],
  },
}));
const OffersSectionSkeleton = () => (
  <StyledBox>
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item lg={4} md={12} sm={12}>
          <Box my={6}>
            <Skeleton variant="text" width="280px" height={30} />
            <Skeleton variant="text" width="280px" height={20} />
            <Skeleton variant="text" width="280px" height={20} />
            <Skeleton variant="text" width="280px" height={20} />
            <Skeleton variant="text" width="200px" height={20} />
          </Box>
        </Grid>
        <Grid item lg={4} md={12} sm={12}>
          <Skeleton variant="rectangular" width="100%" height={600} />
          <Box my={3}>
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="40%" height={50} />
          </Box>
        </Grid>
        <Grid item lg={4} md={12} sm={12}>
          <Skeleton variant="rectangular" width="100%" height={600} />
          <Box my={3}>
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="40%" height={50} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </StyledBox>
);
export { OffersSectionSkeleton };
