import { keyframes } from '@mui/material/styles';

export const flashBlur = (theme) => keyframes`
  0% {
    filter: brightness(1.2) blur(1.5px);
    border: 0;
  }
  100% {
    filter: brightness(1) blur(0px);
    border: 4px solid ${theme.palette.primary.main};
  }
`;

export const flashBlurBack = (theme) => keyframes`
  0% {
    filter: brightness(1.2) blur(3px);
    border: 4px solid ${theme.palette.primary.main};
  }
  100% {
    filter: brightness(1) blur(0);
    border: 0;
  }
`;
