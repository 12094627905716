import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { flashBlur, flashBlurBack } from 'views/common/utils/animations';

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .OCImg': {
    height: theme.typography.pxToRem(560),
    width: '100%',
    flexShrink: 0, // Prevent shrinking
    marginBottom: theme.typography.pxToRem(32),
    [theme.breakpoints.down('xl')]: {
      height: theme.typography.pxToRem(380),
      marginBottom: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(320),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(314),
    },
    overFlow: 'hidden',
    '& .IBimg': {
      transition: 'transform 0.5s ease-in-out',
    },
    '&:hover': {
      animation: `${flashBlur(theme)} 0.5s ease-in-out forwards`,
      '& .IBimg': {
        transform: 'scale(1.1)',
      },
    },
    '&:not(:hover)': {
      animation: `${flashBlurBack(theme)} 0.5s ease-in-out forwards`, // Play the animation in reverse
    },
  },
  '& .OCContent': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .OCCaption': {
    marginBottom: theme.typography.pxToRem(17),
    color: theme.palette.grey.pavement,
    textTransform: 'uppercase',
  },
  '& .OCDesc': {
    flexGrow: 1,
    marginBottom: theme.typography.pxToRem(24),
    color: theme.palette.common.black,
    textTransform: 'uppercase',
  },

  '& .OSBtn': {
    alignSelf: 'flex-start',
    marginTop: 'auto', // Pushes button to bottom if space allows
  },
}));

export default StyledBox;
