import React from 'react';
import {
  Container, Typography, Box, Button,
} from '@mui/material';
import StyledBox from 'views/rebranded-module/HomeViewV2/components/UI/VideoCalloutSection/VideoCalloutSection.styles';
import { useTranslation } from 'next-i18next';
import { Typography700 } from 'views/common/components';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import { Video } from 'views/rebranded-module/common/components';

const VideoCalloutSection = () => {
  const { t } = useTranslation('fe_er_home_page_V2');

  return (
    <StyledBox>
      <Box className="VCSOverlay" />
      <Video
        className="HSVideo"
        videoSrc="/videos/Rebranding/experinceVideo.mp4"
        fallbackImg={{
          src: '/videos/fallbackImgs/experinceVideo.jpg',
          alt: 'EagleRider Experience Showout Video',
          width: '100%',
          height: '100%',
        }}
      />

      <Container maxWidth="xl">
        <Box className="VSSContent">
          <Typography700 variant="h3" component="h3" className="VCSTextTitle">
            {t('fe_er_home_page_V2:experience_title')}
          </Typography700>
          <Typography variant="bodyXL" className="VCSTextDes">
            {t('fe_er_home_page_V2:experience_disc')}
          </Typography>
          <Button
            component={NavigatorLink}
            className="VSCTextBtn"
            variant="contained"
            href={PATHS.motorcycleTrips.index()}>
            {t('fe_er_home_page_V2:our_iconic_rides')}
          </Button>
        </Box>
      </Container>
    </StyledBox>
  );
};
export { VideoCalloutSection };
