import { useSelector } from 'react-redux';

const useAdabtiveMultiSizeImage = () => {
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);

  const getSizedImage = (imageObj) => {
    if (deviceType === 'mobile' || isCrawler) {
      return imageObj.small_url;
    }
    if (deviceType === 'tablet') {
      return imageObj.medium_url;
    }
    if (deviceType === 'desktop') {
      return imageObj.large_url;
    }
    return imageObj.xlarge_url || imageObj.large_url;
  };

  return getSizedImage;
};

export default useAdabtiveMultiSizeImage;
