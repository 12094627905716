import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  height: theme.typography.pxToRem(970),
  position: 'relative',
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down('md')]: {
    hegiht: theme.typography.pxToRem(875),
  },
  '& .IMDesc': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  '& .IMLocationsContainer': {
    width: theme.typography.pxToRem(480),
    backgroundColor: theme.palette.common.fogWhite,
    position: 'absolute',
    left: 0,
    bottom: '50%',
    transform: 'translateY(50%)',
    [theme.breakpoints.down('lg')]: {
      width: theme.typography.pxToRem(312),
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  '& .IMRegionBtn': {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  '& .IMArrowIcon': {
    margin: theme.spacing(1, 0, 1.5, 0),
  },
  '& .IMSeperator': {
    position: 'relative',
    '&:after': {
      content: "''",
      width: '100%',
      height: 1,
      position: 'absolute',
      backgroundColor: theme.palette.grey.concrete,
    },
  },
  '& .IMLocPin': {
    width: theme.typography.pxToRem(50),
    transform: 'translate(-50% , -50%)',
  },
  '& .IMAccordion': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '& .IMSelectRegionBtn': {
    padding: 0,
  },
  '& .IMNoLeftPadding': {
    paddingLeft: 0,
  },
}));

export default StyledBox;
