import { Autocomplete } from '@mui/material';
import React from 'react';
import { IconedBox } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/IconecBox';
import StyledBox, {
  StyledPopper,
} from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/TourWidgetField/TourWidgetField.styles';
import ArrowIcon from 'views/common/components/UI/Icons/ArrowIcon';
import { WidgetTextFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2/WidgetTextFieldV2';

const TourWidgetField = (props) => {
  const {
    options, icon, label, placeholder, ...otherProps
  } = props;

  const getOptionLabel = (option) => option?.name || '';

  return (
    <StyledBox>
      <IconedBox icon={icon}>
        <Autocomplete
          PopperComponent={StyledPopper}
          disablePortal
          options={options}
          popupIcon={<ArrowIcon />}
          getOptionLabel={getOptionLabel}
          {...otherProps}
          renderInput={(params) => (
            <WidgetTextFieldV2
              {...params}
              placeholder={placeholder}
              label={label}
              variant="filled"
            />
          )}
        />
      </IconedBox>
    </StyledBox>
  );
};

export { TourWidgetField };
