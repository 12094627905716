import React from 'react';
import { StyledTextField } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2/WidgetTextFieldV2.styles';
import ArrowIcon from 'views/common/components/UI/Icons/ArrowIcon';

const WidgetTextFieldV2 = (props) => {
  const { InputLabelProps, ...otherProps } = props;
  return (
    <StyledTextField
      variant="filled"
      SelectProps={{
        IconComponent: ArrowIcon,
      }}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      {...otherProps}
    />
  );
};

export { WidgetTextFieldV2 };
