import React from 'react';
import {
  Typography, Box, Grid, Container, Button,
} from '@mui/material';
import StyledBox, {
  StyledCarousel,
} from 'views/rebranded-module/HomeViewV2/components/UI/OffersSection/OffersSection.styles';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import { Typography700 } from 'views/common/components';
import { useSelector } from 'react-redux';
import ArrowYellow from 'views/common/components/UI/Icons/ArrowYellow';
import { OfferCard } from 'views/rebranded-module/HomeViewV2/components';

const ArrowLeft = (arrowLeftProps) => (
  <>
    <span {...arrowLeftProps} className="OSArrow OSArrowLeft">
      <ArrowYellow className="OSArrowIcon" />
    </span>
  </>
);

const ArrowRight = (arrowRightProps) => (
  <>
    <span {...arrowRightProps} className="OSArrow OSArrowRight">
      <ArrowYellow className="OSArrowIcon" />
    </span>
  </>
);
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1330 },
    items: 2,
    partialVisibilityGutter: 70,
  },
  desktop: {
    breakpoint: { max: 1329, min: 1024 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    partialVisibilityGutter: 25,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};

const OffersSection = (props) => {
  const { t } = useTranslation('fe_er_home_page_V2');
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);
  const { offers } = props;

  return (
    <StyledBox>
      <Container maxWidth="xl" className="OSContainer">
        <Grid
          container
          rowSpacing={{ lg: 0, md: 4.75, sm: 4 }}
          className="OSGrid">
          <Grid item xl={3.5} lg={3.5} md={12} sm={12}>
            <Box className="OSTextBox">
              <Typography700 variant="h5" component="h2">
                {t('fe_er_home_page_V2:Offers')}
              </Typography700>
              <Typography variant="body1" component="p">
                {t('fe_er_home_page_V2:Offers_desc')}
              </Typography>
              <Button
                component={NavigatorLink}
                variant="contained"
                color="primary"
                className="OSBtn"
                href={PATHS.motorcycleDeals.index({ ref: 'homepage_deals' })}>
                {t('fe_er_home_page_V2:all_offers')}
              </Button>
            </Box>
          </Grid>

          <Grid item xl={8.5} lg={8.5} md={12} sm={12}>
            <StyledCarousel
              swipeable
              draggable={false}
              responsive={responsive}
              keyBoardControl
              customTransition="all 0.5s"
              renderButtonGroupOutside
              customLeftArrow={<ArrowLeft />}
              customRightArrow={<ArrowRight />}
              autoPlay={false}
              shouldResetAutoplay={false}
              partialVisible={offers.length != 1}
              ssr={isCrawler}
              deviceType={deviceType}
              arrows>
              {offers.map((offer) => (
                <Box className="CarouselItem" key={offer.id}>
                  <OfferCard
                    imageAlt={offer.title}
                    subTitle={offer.sub_promo_text}
                    desciption={offer.promo_text}
                    image={offer.image}
                    type={offer.type}
                    slug={offer.slug}
                  />
                </Box>
              ))}
            </StyledCarousel>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};
export { OffersSection };
