import {
  styled, Box, MenuItem, alpha,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RCSelectField': {
    '& .MuiInputBase-root.MuiFilledInput-root': {
      paddingTop: theme.spacing(2.55),
      '& .MuiSelect-select.MuiSelect-filled': {
        background: 'transparent !important',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        lineHeight: '180%',
        opacity: '1',
        border: 'none !important',
        padding: theme.spacing(0, 0, 0.7, 0),
      },
    },

    '& .MuiSvgIcon-root': {
      top: '50%',
      right: `${theme.typography.pxToRem(16)} !important`,
      color: theme.palette.common.black,
      width: theme.typography.pxToRem(14),
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1, 2),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[100]}`,
  '& .VCLableAndDisc': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  '& .MuiFormControlLabel-label': {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  '& .VCItemDiscount': {
    display: 'inline-block',
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 0.5),
    height: 'fit-content',
  },
  '&.VCItemTitle': {
    color: theme.palette.grey[600],
    opacity: 1,
    padding: 0,
    border: 0,
    alignItems: 'flex-start',
  },
  '& .VCCounterItem': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    backgroundColor: theme.palette.grey.A000,
  },
  '& .VCCounterBike': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  '& .VCBikeIcon': {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '& path': {
      fill: theme.palette.secondary.main,
    },
  },
  '& .VCBikeText': {
    color: theme.palette.secondary.main,
    lineHeight: theme.spacing(3),
  },
  '&.VCItemSelected': {
    backgroundColor: theme.palette.common.fogWhite,
    '& .VCItemDiscount': {
      // backgroundColor: 'transparent',
    },
    '& .MuiRadio-root': {
      color: theme.palette.secondary.main,
    },
  },
  '&.VCCounterMenuItme': {
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    padding: 0,
    '& .VCLableAndDisc': {
      padding: theme.spacing(0.5, 1),
    },
    '&.VCItemSelected': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
      '& .VCCounterItem': {
        backgroundColor: `${theme.palette.primary.A000} !important`,
      },
    },
    '& .VCItemDiscount': {
      backgroundColor: theme.palette.error.A000,
    },
  },
}));

export const StyledMenuBox = styled(Box)(({ theme }) => ({
  '&.VCDoneBtnContainer': {
    padding: theme.spacing(1, 2),
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
  },
  '&.VCloseBtnContainer': {
    textAlign: 'end',
    padding: theme.spacing(0, 2),
    '& .VCloseBtn': {
      padding: 0,
    },
  },
  '& .VCBtn': {
    color: theme.palette.secondary.main,
    borderColor: `${theme.palette.secondary.main} !important`,
  },
}));

export default StyledBox;
