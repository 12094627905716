import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { WidgetTextFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.fogWhite,
  padding: theme.spacing(2.5, 3),
  position: 'relative',
  '& .RWV2CheckAvBtn': {
    width: '100%',
    borderRadius: 0,
    height: '100%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 4),
    },
  },
  '& .RWV2WhiteBG': {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      backgroundColor: alpha(theme.palette.common.white, 0.7),
    },
  },
  '& .RWV2FirstClickOverlay': {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    display: 'flex',
    '& .RWV2FirstClickOverlayInputSection': {
      cursor: 'text',
      width: '75%',
    },
    '& .RWV2FirstClickOverlayActionSection': {
      cursor: 'pointer',
      width: '25%',
    },
  },
  '& .RWCustomMarginTop': {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  '& .RWMarginTop': {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
}));

export const SelectWidgetFieldV2 = styled(WidgetTextFieldV2)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    left: theme.spacing(0.5),
  },
  '& .MuiInputBase-root': {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2.6),
    '& .MuiSelect-select': {
      background: 'transparent !important',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: '180%',
      opacity: '1',
      border: 'none !important',
      padding: '0 !important',
      paddingTop: theme.spacing(0.6),
    },
  },
  '& .RWV2Border': {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '50%',
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export default StyledBox;
