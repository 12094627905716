import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FillTikTokIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none">
    <path
      d="M15.9986 0.527344C7.16482 0.527344 0 7.68007 0 16.499C0 20.9125 1.79056 24.9054 4.68565 27.7956C7.58075 30.6858 11.5804 32.4733 15.9986 32.4733C24.8352 32.4733 32 25.3206 32 16.5017C32 7.68007 24.8352 0.527344 15.9986 0.527344ZM25.4212 14.5147C25.4212 14.5147 22.7773 14.9946 20.209 12.87V20.5593C20.209 20.5593 20.2468 25.8247 14.8806 27.162C14.3216 27.3022 13.7895 27.3615 13.2899 27.3615C10.4866 27.3615 8.7366 25.4743 8.7096 25.4446C7.78057 24.7948 5.19337 20.7966 7.51863 16.9492C10.0734 12.7272 14.3081 13.598 14.3081 13.598V17.4992C14.2865 17.4938 13.876 17.3968 13.3304 17.4345H13.325C11.71 17.5073 11.1132 18.5183 10.9484 18.7367C10.754 18.9929 10.3219 19.5968 10.4164 20.8936C10.5082 22.1688 11.6668 23.0181 11.7019 23.0424C11.7019 23.0424 13.055 24.0211 14.7618 23.25C16.474 22.4762 16.5469 20.6159 16.5469 20.4245C16.5469 20.2331 16.528 5.64183 16.528 5.64183H20.2414C20.2414 5.64183 20.2468 5.90604 20.2738 6.22418C20.2981 6.54232 20.3494 6.91708 20.4358 7.13276C20.4358 7.13276 20.7761 8.35409 21.5431 9.15753C21.6214 9.24111 21.7051 9.31929 21.7943 9.39209C21.8267 9.41635 21.8564 9.44062 21.8888 9.46489C21.9104 9.48645 23.4471 10.9828 25.4158 10.8372V14.512L25.4212 14.5147Z"
      fill="black"
    />
  </SvgIcon>
);

export default FillTikTokIcon;
