import React, { useState } from 'react';
import {
  Container, Typography, Box, Stack, Button,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/rebranded-module/HomeViewV2/components/UI/HeroSection/HeroSection.styles';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { Typography700 } from 'views/common/components';
import { MainWidgetV2, Video } from 'views/rebranded-module/common/components';

const BookNowOverlay = dynamic(() => import('views/rebranded-module/HomeViewV2/components/UI/BookNowOverlay').then(
  (file) => file.BookNowOverlay,
));

const HeroSection = (props) => {
  const { t } = useTranslation('fe_er_home_page_V2');
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  const { initWidgetData } = props;
  const handleOpenOverlayBookingClick = (event) => {
    setOverlayOpen(true);
    event.currentTarget.blur(); // Remove focus immediately after click
  };
  return (
    <StyledBox>
      <Box className="HSVideoWrapper">
        <Video
          videoSrc="/videos/Rebranding/heroVideo.mp4"
          fallbackImg={{
            src: '/imgs/Rebranding/HomePage/heroFallback.jpg',
            alt: 'Eaglerider Vally Image',
            width: '100%',
            height: '1080px',
          }}
          className="HSVideo"
        />

        <Box className="HSWidgetContainer">
          <MainWidgetV2 rentalWidgetInitData={initWidgetData} />
        </Box>
      </Box>

      <Box className="HSTextContent" pb={10}>
        <Container className="HSTextContentContainer">
          <Stack
            gap={{
              xs: 3.5,
              sm: 3.5,
              md: 3.5,
              lg: 5,
            }}>
            <Box display="flex" justifyContent="center">
              <StyledImgBox
                image={{
                  src: '/imgs/Rebranding/HomePage/Logo-icon-hero.png',
                  alt: 'Hero Image',
                }}
                imgHeight="80px"
                imgWidth="80px"
              />
            </Box>
            <Typography700 variant="h3" component="h2" className="HSTextTitle">
              {t('fe_er_home_page_V2:your_next_adventure_starts_now')}
            </Typography700>
            <Typography
              variant="body1"
              className="HSText"
              dangerouslySetInnerHTML={{
                __html: t('fe_er_home_page_V2:hero_text'),
              }}
            />
          </Stack>
        </Container>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="HSFixedButton"
        onClick={handleOpenOverlayBookingClick}>
        {t('fe_er_home_page_V2:book_today')}
      </Button>
      {isOverlayOpen && (
        <BookNowOverlay
          open={isOverlayOpen}
          onClose={() => setOverlayOpen(false)}
        />
      )}
    </StyledBox>
  );
};
export { HeroSection };
