import { styled, Box } from '@mui/material';
import Popper from '@mui/material/Popper';

const StyledBox = styled(Box)(() => ({}));
export const StyledPopper = styled(Popper)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('md')]: {},
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    width: '100% !important',
    '& .MuiAutocomplete-listbox': {
      padding: theme.spacing(1, 0),
      '& .MuiAutocomplete-option': {
        padding: theme.spacing(1.5, 3),
        borderBottom: `1px solid ${theme.palette.common.fogWhite}`,
        '& .WLFOptionText': {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: '120%',
        },
      },
    },
  },
}));

export default StyledBox;
