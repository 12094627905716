import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.fogWhite,
  padding: theme.spacing(2.5, 3),
  [theme.breakpoints.down('md')]: {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& .TWV2CheckAvBtn': {
    width: '100%',
    borderRadius: 0,
    height: '100%',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  '& .TWV2Grid': {
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
    },
    '& .TWV2CheckAvBtn': {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

export default StyledBox;
