import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import StyledBox from 'views/rebranded-module/common/components/Logical/RentalWidgetV2/RentalWidgetV2.styles';
import { WidgetLocationFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetLocationFieldV2';
import {
  ERRORS_PREFIX,
  LOCATION_TYPE,
  RENTAL_WIDGET_FIELDS,
} from 'lib/utils/mainWidgetConstants';
import {
  Box, Button, Grid, MenuItem,
} from '@mui/material';
import { RiderCount } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/RiderCount';
import { MainWidgetDatePickerV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/MainWidgetDatePickerV2';
import dayjs from 'dayjs';
import { SelectWidgetFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/SelectWidgetFieldV2';
import ArrowIcon from '@mui/icons-material/ExpandMore';
import ReCAPTCHA from 'react-google-recaptcha';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const RentalWidgetV2 = (props) => {
  const { t } = useTranslation('common_rental_widget_v2');

  const {
    pickupData,
    dropoffData,
    motorcycleList,
    handleLocationInputChange,
    formData,
    handleFormDataChange,
    shouldDisableDate,
    isOptionsLoading,
    handleSearchButtonClick,
    //  rentalWidgetNotifications,
    disableVehicleCount,
    errors,
    disabled,
    recaptchaVehicleCountThreshold,
    recaptchaRef,
    initRenderFullWidget = false,
  } = props;
  const [renderFullWidget, setRenderFullWidget] = useState(initRenderFullWidget);

  const handleOneLineWidgetOverlayClickk = () => {
    setRenderFullWidget(true);
    document
      .querySelector('#RWV2PickupLocContainer .MuiInputBase-root')
      ?.click();
  };

  const handleSearch = (recaptchaToken) => {
    if (renderFullWidget) {
      handleSearchButtonClick(recaptchaToken);
    }
  };
  const handleRecaptchaButtonClick = (e) => {
    e.preventDefault();

    if (
      formData.reservation.vehicles_count >= recaptchaVehicleCountThreshold
      && renderFullWidget
    ) {
      recaptchaRef.current.execute();
    } else {
      handleSearch();
    }
  };

  useEffect(() => {
    if (
      formData.reservation?.pickup_location_id
      || formData.reservation?.dropoff_location_id
    ) {
      setRenderFullWidget(true);
    }
  }, []);

  return (
    <StyledBox>
      <Grid
        container
        columnSpacing={{
          xs: 1,
          sm: renderFullWidget ? 1 : 0.1,
        }}
        rowSpacing={{ md: 0.1, sm: 1 }}
        position="relative">
        {!renderFullWidget && (
          <Box
            className="RWV2FirstClickOverlay"
            onClick={handleOneLineWidgetOverlayClickk}>
            <Box className="RWV2FirstClickOverlayInputSection" />
            <Box className="RWV2FirstClickOverlayActionSection">
              <Button variant="contained" className="RWV2CheckAvBtn">
                {t('common_rental_widget_v2:check_availability')}
              </Button>
            </Box>
          </Box>
        )}
        <Grid
          item
          xs={12}
          md={6}
          lg={renderFullWidget ? 4 : 3}
          id="RWV2PickupLocContainer">
          <Box className="RWV2WhiteBG">
            <WidgetLocationFieldV2
              fieldName={RENTAL_WIDGET_FIELDS.PICKUP_LOCATION_ID}
              selectedLocationId={formData.reservation?.pickup_location_id}
              locationsList={pickupData.locations}
              label={t('common_rental_widget_v2:pickup_loc')}
              placeholder={t('common_rental_widget_v2:choose_loc')}
              handleInputChange={handleLocationInputChange}
              handleLocationChange={handleFormDataChange}
              loading={isOptionsLoading[LOCATION_TYPE.PICKUP]}
              errors={errors}
              disabled={disabled}
              fieldType={LOCATION_TYPE.PICKUP}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={renderFullWidget ? 4 : 3}>
          <Box className="RWV2WhiteBG">
            <WidgetLocationFieldV2
              fieldName={RENTAL_WIDGET_FIELDS.DROPOFF_LOCATION_ID}
              selectedLocationId={formData.reservation?.dropoff_location_id}
              locationsList={dropoffData.locations}
              label={t('common_rental_widget_v2:dropoff_loc')}
              placeholder={t('common_rental_widget_v2:choose_loc')}
              handleInputChange={handleLocationInputChange}
              handleLocationChange={handleFormDataChange}
              loading={isOptionsLoading[LOCATION_TYPE.DROPOFF]}
              errors={errors}
              disabled={disabled}
              fieldType={LOCATION_TYPE.DROPOFF}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={renderFullWidget ? 'RWMarginTop' : ''}
          lg={renderFullWidget ? 4 : 3}
          order={{ xs: 2, lg: 0 }}>
          <Box className="RWV2WhiteBG">
            <RiderCount
              errors={errors}
              disableVehicleCount={disableVehicleCount || disabled}
              handleFormDataChange={handleFormDataChange}
              formData={formData}
              motorcycleList={motorcycleList}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={renderFullWidget ? 4 : 3}
          order={6}
          className={renderFullWidget && 'RWCustomMarginTop'}>
          <Button
            variant="contained"
            className="RWV2CheckAvBtn"
            onClick={handleRecaptchaButtonClick}>
            {t('common_rental_widget_v2:check_availability')}
          </Button>
        </Grid>
        {renderFullWidget && (
          <>
            <Grid item container xs={12} md={6} lg={4} spacing={0}>
              <Grid item xs={7.2} className="RWV2WhiteBG">
                <MainWidgetDatePickerV2
                  label={(
                    <>
                      <span className="showMOBILE">
                        {t('common_rental_widget_v2:pickup_date')}
                      </span>
                      <span className="showTabletAndDesktop">
                        {t('common_rental_widget_v2:date')}
                      </span>
                    </>
                  )}
                  value={dayjs(formData.reservation?.pickup_date || null)}
                  fieldType={RENTAL_WIDGET_FIELDS.PICKUP_DATE}
                  onChange={(value) => {
                    if (
                      value
                      && !dayjs(formData.reservation?.pickup_date).isSame(value)
                      && !shouldDisableDate(true, value)
                      && dayjs(value).isBetween(
                        dayjs(pickupData.minDate),
                        dayjs(pickupData.maxDate),
                        'day',
                        '[]',
                      )
                    ) {
                      handleFormDataChange(
                        RENTAL_WIDGET_FIELDS.PICKUP_DATE,
                        value,
                      );
                    }
                  }}
                  minDate={dayjs(pickupData.minDate)}
                  maxDate={dayjs(pickupData.maxDate)}
                  shouldDisableDate={(date) => shouldDisableDate(true, date)}
                  id={`${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.PICKUP_DATE}`}
                  error={
                    !!errors[
                      `${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.PICKUP_DATE}`
                    ]
                  }
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4.8} className="RWV2WhiteBG RWV2Border">
                <SelectWidgetFieldV2
                  label={t('common_rental_widget_v2:time')}
                  select
                  fullWidth
                  id={`${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT}`}
                  error={
                    !!errors[
                      `${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT}`
                    ]
                  }
                  value={formData.reservation?.pickup_time_slot || ''}
                  onChange={(e) => handleFormDataChange(
                    RENTAL_WIDGET_FIELDS.PICKUP_TIME_SLOT,
                    e.target.value,
                  )}
                  IconComponent={ArrowIcon}
                  disabled={disabled}>
                  {pickupData.timeSlots.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{ textTransform: 'uppercase' }}>
                      {option}
                    </MenuItem>
                  ))}
                </SelectWidgetFieldV2>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} lg={4} spacing={0}>
              <Grid item xs={7.2} className="RWV2WhiteBG">
                <MainWidgetDatePickerV2
                  label={(
                    <>
                      <span className="showMOBILE">
                        {t('common_rental_widget_v2:dropoff_date')}
                      </span>
                      <span className="showTabletAndDesktop">
                        {t('common_rental_widget_v2:date')}
                      </span>
                    </>
                  )}
                  value={dayjs(formData.reservation?.dropoff_date || null)}
                  fieldType={RENTAL_WIDGET_FIELDS.DROPOFF_DATE}
                  onChange={(value) => {
                    if (
                      value
                      && !dayjs(formData.reservation?.dropoff_date).isSame(
                        value,
                      )
                      && !shouldDisableDate(true, value)
                      && dayjs(value).isBetween(
                        dayjs(dropoffData.minDate),
                        dayjs(dropoffData.maxDate),
                        'day',
                        '[]',
                      )
                    ) {
                      handleFormDataChange(
                        RENTAL_WIDGET_FIELDS.DROPOFF_DATE,
                        value,
                      );
                    }
                  }}
                  minDate={dayjs(dropoffData.minDate)}
                  maxDate={dayjs(dropoffData.maxDate)}
                  shouldDisableDate={(date) => shouldDisableDate(true, date)}
                  id={`${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.DROPOFF_DATE}`}
                  error={
                    !!errors[
                      `${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.DROPOFF_DATE}`
                    ]
                  }
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4.8} className="RWV2WhiteBG RWV2Border">
                <SelectWidgetFieldV2
                  label={t('common_rental_widget_v2:time')}
                  select
                  fullWidth
                  id={`${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT}`}
                  error={
                    !!errors[
                      `${ERRORS_PREFIX}${RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT}`
                    ]
                  }
                  value={formData.reservation?.dropoff_time_slot || ''}
                  onChange={(e) => handleFormDataChange(
                    RENTAL_WIDGET_FIELDS.DROPOFF_TIME_SLOT,
                    e.target.value,
                  )}
                  disabled={disabled}
                  IconComponent={ArrowIcon}>
                  {dropoffData.timeSlots.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{ textTransform: 'uppercase' }}>
                      {option}
                    </MenuItem>
                  ))}
                </SelectWidgetFieldV2>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {envVars.INVISIBLE_RECAPTCHA_SITE_KEY
        && formData.reservation.vehicles_count >= recaptchaVehicleCountThreshold
        && renderFullWidget && (
          <ReCAPTCHA
            className="SACRecaptchaBadge"
            ref={recaptchaRef}
            sitekey={envVars.INVISIBLE_RECAPTCHA_SITE_KEY}
            size="invisible"
            onChange={(recaptchaToken) => {
              handleSearch(recaptchaToken);
            }}
          />
      )}
    </StyledBox>
  );
};

export { RentalWidgetV2 };
