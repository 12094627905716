import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CalendarIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    {...props}>
    <path
      d="M18.0938 11.2812C18.6115 11.2812 19.0312 10.8615 19.0312 10.3438C19.0312 9.82598 18.6115 9.40625 18.0938 9.40625C17.576 9.40625 17.1562 9.82598 17.1562 10.3438C17.1562 10.8615 17.576 11.2812 18.0938 11.2812Z"
      fill="black"
    />
    <path
      d="M20.25 2.375H19.0312V1.4375C19.0312 0.919719 18.6115 0.5 18.0938 0.5C17.576 0.5 17.1562 0.919719 17.1562 1.4375V2.375H12.8906V1.4375C12.8906 0.919719 12.4709 0.5 11.9531 0.5C11.4353 0.5 11.0156 0.919719 11.0156 1.4375V2.375H6.79688V1.4375C6.79688 0.919719 6.37716 0.5 5.85938 0.5C5.34159 0.5 4.92188 0.919719 4.92188 1.4375V2.375H3.75C1.68225 2.375 0 4.05725 0 6.125V20.75C0 22.8177 1.68225 24.5 3.75 24.5H10.9219C11.4397 24.5 11.8594 24.0803 11.8594 23.5625C11.8594 23.0447 11.4397 22.625 10.9219 22.625H3.75C2.71613 22.625 1.875 21.7839 1.875 20.75V6.125C1.875 5.09113 2.71613 4.25 3.75 4.25H4.92188V5.1875C4.92188 5.70528 5.34159 6.125 5.85938 6.125C6.37716 6.125 6.79688 5.70528 6.79688 5.1875V4.25H11.0156V5.1875C11.0156 5.70528 11.4353 6.125 11.9531 6.125C12.4709 6.125 12.8906 5.70528 12.8906 5.1875V4.25H17.1562V5.1875C17.1562 5.70528 17.576 6.125 18.0938 6.125C18.6115 6.125 19.0312 5.70528 19.0312 5.1875V4.25H20.25C21.2839 4.25 22.125 5.09113 22.125 6.125V11.4688C22.125 11.9865 22.5447 12.4062 23.0625 12.4062C23.5803 12.4062 24 11.9865 24 11.4688V6.125C24 4.05725 22.3177 2.375 20.25 2.375Z"
      fill="black"
    />
    <path
      d="M18.3281 13.1562C15.2006 13.1562 12.6562 15.7006 12.6562 18.8281C12.6562 21.9556 15.2006 24.5 18.3281 24.5C21.4556 24.5 24 21.9556 24 18.8281C24 15.7006 21.4556 13.1562 18.3281 13.1562ZM18.3281 22.625C16.2345 22.625 14.5312 20.9218 14.5312 18.8281C14.5312 16.7345 16.2345 15.0312 18.3281 15.0312C20.4217 15.0312 22.125 16.7345 22.125 18.8281C22.125 20.9218 20.4217 22.625 18.3281 22.625Z"
      fill="black"
    />
    <path
      d="M19.6875 17.8906H19.2656V16.9062C19.2656 16.3885 18.8459 15.9688 18.3281 15.9688C17.8103 15.9688 17.3906 16.3885 17.3906 16.9062V18.8281C17.3906 19.3459 17.8103 19.7656 18.3281 19.7656H19.6875C20.2053 19.7656 20.625 19.3459 20.625 18.8281C20.625 18.3103 20.2053 17.8906 19.6875 17.8906Z"
      fill="black"
    />
    <path
      d="M14.0156 11.2812C14.5334 11.2812 14.9531 10.8615 14.9531 10.3438C14.9531 9.82598 14.5334 9.40625 14.0156 9.40625C13.4979 9.40625 13.0781 9.82598 13.0781 10.3438C13.0781 10.8615 13.4979 11.2812 14.0156 11.2812Z"
      fill="black"
    />
    <path
      d="M9.9375 15.3594C10.4553 15.3594 10.875 14.9396 10.875 14.4219C10.875 13.9041 10.4553 13.4844 9.9375 13.4844C9.41973 13.4844 9 13.9041 9 14.4219C9 14.9396 9.41973 15.3594 9.9375 15.3594Z"
      fill="black"
    />
    <path
      d="M5.85938 11.2812C6.37714 11.2812 6.79688 10.8615 6.79688 10.3438C6.79688 9.82598 6.37714 9.40625 5.85938 9.40625C5.34161 9.40625 4.92188 9.82598 4.92188 10.3438C4.92188 10.8615 5.34161 11.2812 5.85938 11.2812Z"
      fill="black"
    />
    <path
      d="M5.85938 15.3594C6.37714 15.3594 6.79688 14.9396 6.79688 14.4219C6.79688 13.9041 6.37714 13.4844 5.85938 13.4844C5.34161 13.4844 4.92188 13.9041 4.92188 14.4219C4.92188 14.9396 5.34161 15.3594 5.85938 15.3594Z"
      fill="black"
    />
    <path
      d="M5.85938 19.4375C6.37714 19.4375 6.79688 19.0178 6.79688 18.5C6.79688 17.9822 6.37714 17.5625 5.85938 17.5625C5.34161 17.5625 4.92188 17.9822 4.92188 18.5C4.92188 19.0178 5.34161 19.4375 5.85938 19.4375Z"
      fill="black"
    />
    <path
      d="M9.9375 19.4375C10.4553 19.4375 10.875 19.0178 10.875 18.5C10.875 17.9822 10.4553 17.5625 9.9375 17.5625C9.41973 17.5625 9 17.9822 9 18.5C9 19.0178 9.41973 19.4375 9.9375 19.4375Z"
      fill="black"
    />
    <path
      d="M9.9375 11.2812C10.4553 11.2812 10.875 10.8615 10.875 10.3438C10.875 9.82598 10.4553 9.40625 9.9375 9.40625C9.41973 9.40625 9 9.82598 9 10.3438C9 10.8615 9.41973 11.2812 9.9375 11.2812Z"
      fill="black"
    />
  </SvgIcon>
);

export default CalendarIcon;
