import React from 'react';
import {
  Box, Skeleton, styled, Grid,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.grey[700],
  },
}));
const IntroSectionSkeleton = () => (
  <StyledBox>
    <Grid container>
      <Grid item lg={6} md={12} sm={12}>
        <Skeleton variant="rectangular" width="100%" height={800} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} sx={{ order: { lg: 1, md: 2 } }}>
        <Box my={9} px={3}>
          <Skeleton variant="text" width="50%" height={50} />
          <Skeleton variant="text" width="70%" height={30} />
          <Skeleton variant="text" width="70%" height={30} />
          <Skeleton variant="text" width="70%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="30%" height={50} />
        </Box>
      </Grid>
    </Grid>
  </StyledBox>
);
export { IntroSectionSkeleton };
