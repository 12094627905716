import React from 'react';
import { Box, Skeleton, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.grey[700],
  },
}));
const InteractiveMapSkeleton = () => (
  <StyledBox>
    <Skeleton variant="rectangular" width="100%" height={800} />
  </StyledBox>
);
export { InteractiveMapSkeleton };
