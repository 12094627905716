import { styled } from '@mui/material/styles';
import { WidgetTextFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2';

export const SelectWidgetFieldV2 = styled(WidgetTextFieldV2)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    left: theme.spacing(0.5),
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiInputBase-root.MuiFilledInput-root': {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2.6),
    '& .MuiSelect-select': {
      background: 'transparent !important',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: '180%',
      opacity: '1',
      border: 'none !important',
      padding: '0 !important',
      paddingTop: theme.spacing(0.6),
    },
  },
  '& .MuiList-root': {
    backgroundColor: 'red',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.black,
    top: `calc(50% - ${theme.typography.pxToRem(3)})`,
    right: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(14),
  },
}));
