import { SvgIcon } from '@mui/material';
import * as React from 'react';

const LocationsIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    {...props}>
    <path
      d="M16.8952 0.5C10.7102 0.5 5.69519 5.4764 5.69519 11.6138C5.69519 14.4204 6.76863 17.0946 8.19419 19.2784C10.6307 23.0093 16.8952 32.5 16.8952 32.5C16.8952 32.5 23.1597 23.0093 25.5962 19.2784C27.0217 17.0946 28.0952 14.4204 28.0952 11.6138C28.0952 5.4764 23.0802 0.5 16.8952 0.5ZM16.8952 17.8272C13.4363 17.8272 10.6335 15.046 10.6335 11.6138C10.6335 8.18158 13.4363 5.40032 16.8952 5.40032C20.354 5.40032 23.1568 8.18158 23.1568 11.6138C23.1568 15.046 20.354 17.8272 16.8952 17.8272Z"
      fill="black"
    />
  </SvgIcon>
);

export default LocationsIcon;
