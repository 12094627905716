import {
  styled, Box, Popper, alpha,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    backgroundColor: alpha(theme.palette.common.white, 0.7),
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {},
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    width: '100% !important',
    '& .MuiAutocomplete-listbox': {
      padding: theme.spacing(1, 0),
      '& .MuiAutocomplete-option': {
        padding: theme.spacing(1.5, 3),
        borderBottom: `1px solid ${theme.palette.common.fogWhite}`,
        '&.MuiAutocomplete-option': {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: '120%',
        },
      },
    },
  },
}));

export default StyledBox;
