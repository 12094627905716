import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FillFaceBookIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <path
      d="M32 16.0523C32 24.2309 25.8422 30.9748 17.9001 31.9125V20.0664H22.6288V16.2726H17.9001V12.8362C17.9001 11.4687 19.0116 10.3617 20.3815 10.3617H22.6288V6.56795H19.5687C16.549 6.56795 14.0972 9.01293 14.0972 12.0275V16.2753H9.36855V20.0691H14.0972V31.9152C10.4397 31.4826 7.15896 29.8168 4.68562 27.3476C1.78974 24.4566 0 20.464 0 16.055C0 11.646 1.78974 7.65073 4.68562 4.75974C7.5815 1.86876 11.5808 0.0820312 15.9973 0.0820312C20.4138 0.0820312 24.4158 1.86876 27.3117 4.75974C30.2076 7.65073 31.9973 11.6433 31.9973 16.055L32 16.0523Z"
      fill="black"
    />
  </SvgIcon>
);

export default FillFaceBookIcon;
