import React, { useEffect, useState } from 'react';
import { InteractiveMap } from 'views/rebranded-module/common/components/UI/InteractiveMap';
import axios, { API } from 'lib/config/axios';
import { haversineDistance } from 'lib/utils/haversineDistance';
import { useGetRegions } from 'views/rebranded-module/common/components/Logical/InteractiveMapWrapper/hooks/useGetRegions';
import { useMediaQuery, useTheme } from '@mui/material';
import ScrollTo from 'lib/utils/ScrollTo';

const InteractiveMapWrapper = (props) => {
  const { mapInfo } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [locations, setLocations] = useState(mapInfo);
  const regions = useGetRegions();

  const getLocations = () => {
    axios.get(API.locations).then((res) => {
      setLocations(res.data.map_info);
    });
  };
  const isSameRegion = (regionMapInfo, mapCtl) => {
    const zoom = mapCtl.map.getZoom();
    const center = mapCtl.map.getCenter();

    const centerDiff = haversineDistance(
      regionMapInfo.center.lat,
      regionMapInfo.center.lng,
      center.lat(),
      center.lng(),
    );
    return zoom === regionMapInfo.zoom && centerDiff <= 0.01;
  };

  const getRegionScreenSizeMapInfo = (region) => (isMobile ? region.mapInfo.mobile : region.mapInfo.desktop);

  const handleRegionClick = (region, mapCtl) => {
    const regionMapInfo = getRegionScreenSizeMapInfo(region);

    // eslint-disable-next-line no-undef
    const latLng = new google.maps.LatLng(
      regionMapInfo.center.lat,
      regionMapInfo.center.lng,
    ); // Makes a latlng

    if (isSameRegion(regionMapInfo, mapCtl)) return;

    mapCtl.map.setZoom(1);
    setTimeout(() => {
      mapCtl.map.panTo(latLng);
      mapCtl.map.setZoom(regionMapInfo.zoom);
      if (isMobile) {
        ScrollTo('interactive-map-container');
      }
    }, 300);
  };

  useEffect(() => {
    if (!mapInfo) getLocations();
  }, []);

  return (
    <InteractiveMap
      markers={locations}
      defaultCenter={regions[0].mapInfo.desktop.center}
      defaultZoom={regions[0].mapInfo.desktop.zoom}
      regions={regions}
      handleRegionClick={handleRegionClick}
    />
  );
};

export { InteractiveMapWrapper };
