import { styled, TextField } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root.MuiFilledInput-root': {
    borderRadius: 0,
    background: 'transparent !important',
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:before, &:after': {
      content: 'unset',
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: '180%',
      opacity: '1',
      border: 'none !important',
      padding: theme.spacing(1, 0),
      '&::placeholder': {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        lineHeight: '180%',
        opacity: '1',
      },
    },
  },
  '& .MuiFormLabel-root': {
    left: theme.spacing(7.6),
    top: theme.spacing(0.25),
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    letterSpacing: '0.5px',
    color: theme.palette.grey[3],
    textTransform: 'uppercase',
    opacity: '1',
    lineHeight: '140%',
  },
  '& .MuiAutocomplete-endAdornment': {
    top: '50%',
    transform: 'translateY(-30%)',
    right: `${theme.typography.pxToRem(16)} !important`,
    '& .MuiButtonBase-root': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&.MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.black,
      width: theme.typography.pxToRem(14),
    },
  },
}));

export { StyledTextField };
