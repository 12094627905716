import React from 'react';
import {
  Container, Typography, Box, Grid,
} from '@mui/material';
import StyledBox from 'views/rebranded-module/HomeViewV2/components/UI/CardsLayoutSection/CardsLayoutSection.styles';
import { useTranslation } from 'next-i18next';
import { Typography700, ImgBox } from 'views/common/components';
import { useSelector } from 'react-redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';

const CardsLayoutSection = () => {
  const { t } = useTranslation('fe_er_home_page_V2');
  // const userHasMembership = true; // Change to false to test
  const { showMembership } = useSelector((state) => state.globalData);

  const mainCards = [
    {
      id: 1,
      title: t('fe_er_home_page_V2:rental'),
      desc: t('fe_er_home_page_V2:rental_desc'),
      linkText: t('fe_er_home_page_V2:rental_btn'),
      linkPath: PATHS.motorcycleRental(),
      imgSrc: '/imgs/Rebranding/HomePage/rentalImg.png',
      SMImgSrc: '/imgs/Rebranding/HomePage/SMRental.png',
    },
    {
      id: 2,
      title: t('fe_er_home_page_V2:Off_road'),
      desc: t('fe_er_home_page_V2:Off_road_desc'),
      linkText: t('fe_er_home_page_V2:Off_road_btn'),
      linkPath: PATHS.guidedOffroadTour.index(),
      imgSrc: '/imgs/Rebranding/HomePage/offRoadImg.jpg',
      SMImgSrc: '/imgs/Rebranding/HomePage/SMOffRoad.jpg',
    },
  ];

  const cards = [
    ...mainCards,
    showMembership
      ? {
        id: 3,
        title: t('fe_er_home_page_V2:club'),
        desc: t('fe_er_home_page_V2:club_desc'),
        linkText: t('fe_er_home_page_V2:club_btn'),
        linkPath: PATHS.motorcycleClub.index(),
        imgSrc: '/imgs/Rebranding/HomePage/clubImg.png',
        SMImgSrc: '/imgs/Rebranding/HomePage/SMClub.png',
      }
      : {
        id: 4,
        title: t('fe_er_home_page_V2:tours'),
        desc: t('fe_er_home_page_V2:tours_desc'),
        linkText: t('fe_er_home_page_V2:tours_btn'),
        linkPath: PATHS.motorcycleTours.index(),
        imgSrc: '/imgs/Rebranding/HomePage/toursImg.png',
        SMImgSrc: '/imgs/Rebranding/HomePage/toursImg.png',
      },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Grid
          container
          columnSpacing={{ xl: 6, lg: 4 }}
          rowSpacing={{ md: 3.75, sm: 3.5 }}>
          {cards.map((card) => (
            <Grid item lg={4} md={12} sm={12} key={card.id}>
              <Box className="CLSCard">
                <Box className="CLSCardContent">
                  <Box className="CLSTextcontent">
                    <Typography700 variant="h3" className="CLSCardTitle">
                      {card.title}
                    </Typography700>
                    <Typography
                      compoeent="p"
                      variant="body2"
                      className="CLSCardDesc">
                      {card.desc}
                    </Typography>
                    <NavigatorLink
                      className="CLSCardBtn"
                      href={card.linkPath}
                      target="_blank">
                      <Typography className="CLSCardBtnText" variant="span">
                        {card.linkText}
                      </Typography>
                      <KeyboardArrowRightIcon className="CLSCardBtnIcon" />
                    </NavigatorLink>
                  </Box>
                </Box>
                <Box className="ISOverlyer" />
                <Box className="CLSImg showDESKTOP">
                  <ImgBox
                    image={{
                      src: card.imgSrc,
                      alt: card.title,
                    }}
                    imgHeight="100%"
                    imgWidth="100%"
                  />
                </Box>
                <Box className="CLSImg showTABLET">
                  <ImgBox
                    image={{
                      src: card.SMImgSrc,
                      alt: card.title,
                    }}
                    imgHeight="100%"
                    imgWidth="100%"
                  />
                </Box>
                {card.videoSrc && (
                  <>
                    <Box
                      component="video"
                      className="CLSVideo showLargeDESKTOP"
                      autoPlay
                      muted
                      playsInline
                      loop>
                      <Box
                        component="source"
                        src={card.videoSrc.lg}
                        type="video/mp4"
                      />
                    </Box>
                    <Box
                      component="video"
                      className="CLSVideo showMobileAndTabletAndDesktop"
                      autoPlay
                      muted
                      playsInline
                      loop>
                      <Box
                        component="source"
                        src={card.videoSrc.sm}
                        type="video/webm"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledBox>
  );
};
export { CardsLayoutSection };
