import { styled, Popper } from '@mui/material';
import { LocalizedDatePicker } from 'views/common/components';
import { IconedBox } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/IconecBox';

const StyledIconedBox = styled(IconedBox)(() => ({}));

export const StyledLocalizedDatePicker = styled(LocalizedDatePicker)(
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      borderRadius: 0,
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(9),
      paddingTop: theme.spacing(2),
      '&:before, &:after': {
        content: 'unset',
      },
      '& .MuiInputBase-input': {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        lineHeight: '180%',
        opacity: '1',
        border: 'none !important',
        padding: theme.spacing(1, 0),
        '&::placeholder': {
          fontSize: theme.typography.pxToRem(16),
          fontWeight: 400,
          lineHeight: '180%',
          opacity: '1',
        },
      },
    },
    '& .MuiFormLabel-root': {
      left: theme.spacing(7.6),
      top: theme.spacing(0.25),
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 700,
      letterSpacing: '0.5px',
      color: theme.palette.grey[3],
      textTransform: 'uppercase',
      opacity: '1',
      lineHeight: '140%',
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },

    '& .MuiInputAdornment-root': {
      display: 'none',
    },
  }),
);

export const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    '& .MuiPickersCalendarHeader-label': {
      fontFamily: theme.typography.fontFamilySecondary,
      fontSize: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
    },
    '& .MuiDayCalendar-header': {
      '& .MuiDayCalendar-weekDayLabel': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: theme.palette.common.black,
      },
    },
    '& .MuiPickersDay-root': {
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.common.white,
      fontWeight: 600,
    },
  },
}));
export const StyledDialog = {
  '& .MuiPickersDay-root': {
    fontSize: (theme) => theme.typography.pxToRem(14),
  },
  '& .MuiDayCalendar-header': {
    '& .MuiDayCalendar-weekDayLabel': {
      fontSize: (theme) => theme.typography.pxToRem(14),
      fontWeight: 600,
      color: '(theme) => theme.palette.common.black',
    },
  },
};

export default StyledIconedBox;
