import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { StyledBox } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/IconecBox/IconedBox.styles';

const IconedBox = (props) => {
  const { icon, error, children } = props;
  return (
    <StyledBox>
      <Box className={clsx('IBIcon', error && 'IBError')}>{icon}</Box>
      <Box flex="1 1">{children}</Box>
    </StyledBox>
  );
};

export { IconedBox };
