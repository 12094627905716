import { alpha, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  borderLeft: '4px solid',
  borderColor: theme.palette.common.black,
  backgroundColor: alpha(theme.palette.primary.main, 0.9),
  padding: theme.spacing(1.5),
  display: 'flex',
  '& .WWWarningText': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    fontFamily: theme.typography.fontFamilySecondary,
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  '& .MWWarningItemt': {
    fontSize: theme.typography.pxToRem(16),
  },
  '& .WWCloseIcon': {
    color: theme.palette.common.white,
    alignSelf: 'end',
  },
}));

export default StyledBox;
