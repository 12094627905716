import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import StyledBox from 'views/rebranded-module/HomeViewV2/components/UI/UserGeneratedContentSection/UserGeneratedContentSection.styles';
import { useTranslation } from 'next-i18next';
import { Typography700, ImgBox } from 'views/common/components';
import FillFaceBookIcon from 'views/common/components/UI/Icons/FillFaceBookIcon';
import FillYoutubeIcon from 'views/common/components/UI/Icons/FillYoutubeIcon';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import FillInstegramIcon from 'views/common/components/UI/Icons/FillInstegramIcon';
import FillTikTokIcon from 'views/common/components/UI/Icons/FillTikTokIcon';

const UserGeneratedContentSection = () => {
  const { t } = useTranslation('fe_er_home_page_V2');
  const socialLinks = [
    {
      href: 'https://www.facebook.com/EagleRider/',
      icon: <FillFaceBookIcon />,
    },
    {
      href: 'https://www.instagram.com/eaglerider/',
      icon: <FillInstegramIcon />,
    },
    {
      href: 'https://www.youtube.com/user/EagleRiderCorporate',
      icon: <FillYoutubeIcon />,
    },
    {
      href: 'https://www.tiktok.com/@clubeaglerider',
      icon: <FillTikTokIcon />,
    },
  ];
  return (
    <StyledBox>
      <Grid container>
        <Grid item lg={3} md={4} sm={12}>
          <Box className="UGCSImgContiner UGCSLongImgContiner">
            <ImgBox
              image={{
                src: '/imgs/Rebranding/HomePage/UGC1.jpg',
                alt: 'page-BG',
              }}
              imgHeight="100%"
              imgWidth="100%"
            />
          </Box>
        </Grid>
        <Grid item lg={9} md={8} sm={12}>
          <Grid container>
            <Grid item lg={3} md={6} sm={12}>
              <Box className="UGCSImgContiner">
                <ImgBox
                  image={{
                    src: '/imgs/Rebranding/HomePage/UGC2.jpg',
                    alt: 'page-BG',
                  }}
                  imgHeight="100%"
                  imgWidth="100%"
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Box className="UGCSImgContiner UGCSImgCustomPosition">
                <ImgBox
                  image={{
                    src: '/imgs/Rebranding/HomePage/UGC3.jpg',
                    alt: 'page-BG',
                  }}
                  imgHeight="100%"
                  imgWidth="100%"
                />
              </Box>
            </Grid>
            <Grid item lg={3} md={0} sm={0} className="showDESKTOP">
              <Box className="UGCSImgContiner">
                <ImgBox
                  image={{
                    src: '/imgs/Rebranding/HomePage/UGC4.jpg',
                    alt: 'page-BG',
                  }}
                  imgHeight="100%"
                  imgWidth="100%"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={3} md={6} sm={12} className="UGCCustomOrder">
              <Box className="UGCSImgContiner UGCSImgOtherCustomPosition">
                <ImgBox
                  image={{
                    src: '/imgs/Rebranding/HomePage/UGC5.jpg',
                    alt: 'page-BG',
                  }}
                  imgHeight="100%"
                  imgWidth="100%"
                />
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <Box className="UGCSTextContiner">
                <Typography700 variant="h6" component="p" className="UGCSText">
                  {t('fe_er_home_page_V2:share_experiences')}
                </Typography700>
                <Box className="UGCSIconBtnContainer">
                  {socialLinks.map((link) => (
                    <IconButton
                      className="UGCSIconBtn"
                      href={link.href}
                      target="_blank"
                      component={NavigatorLink}>
                      {link.icon}
                    </IconButton>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} md={0} sm={0} className="showDESKTOP">
              <Box className="UGCSImgContiner">
                <ImgBox
                  image={{
                    src: '/imgs/Rebranding/HomePage/UGC6.jpg',
                    alt: 'page-BG',
                  }}
                  imgHeight="100%"
                  imgWidth="100%"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledBox>
  );
};
export { UserGeneratedContentSection };
