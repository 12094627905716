import React from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import StyledBox from 'views/rebranded-module/common/components/Logical/TourWidgetV2/TourWidgetV2.styles';
import { TourWidgetField } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/TourWidgetField';
import ToursIcon from 'views/common/components/UI/Icons/ToursIcon';
import LocationsIcon from 'views/common/components/UI/Icons/LocationsIcon';
import ToursNameIcon from 'views/common/components/UI/Icons/ToursNameIcon';
import { TOUR_WIDGET_FIELDS } from 'lib/utils/mainWidgetConstants';

const TourWidgetV2 = (props) => {
  const { t } = useTranslation('common_tour_widget_v2');
  const {
    tourTypesList,
    countriesList,
    toursList,
    handleSelectInputChange,
    selectedOptions,
    handleSearchButtonClick,
  } = props;

  const getFieldValue = (options, selectedOptionField) => options.find(
    (option) => option.id === selectedOptions[selectedOptionField]?.id,
  ) || null;

  return (
    <StyledBox>
      <Grid container spacing={0.1} className="TWV2Grid">
        <Grid item xs={12} md={6} lg={3}>
          <TourWidgetField
            icon={<ToursIcon />}
            options={tourTypesList}
            label={t('common_tour_widget_v2:tour_type')}
            value={getFieldValue(tourTypesList, TOUR_WIDGET_FIELDS.TOUR_TYPE)}
            onChange={(...args) => {
              handleSelectInputChange(TOUR_WIDGET_FIELDS.TOUR_TYPE, ...args);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TourWidgetField
            icon={<LocationsIcon />}
            options={countriesList}
            label={t('common_tour_widget_v2:country')}
            placeholder={t('common_tour_widget_v2:all_countries')}
            value={getFieldValue(countriesList, TOUR_WIDGET_FIELDS.COUNTRY)}
            onChange={(...args) => {
              handleSelectInputChange(TOUR_WIDGET_FIELDS.COUNTRY, ...args);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TourWidgetField
            icon={<ToursNameIcon />}
            options={toursList}
            label={t('common_tour_widget_v2:tour')}
            placeholder={t('common_tour_widget_v2:all_tour')}
            value={getFieldValue(toursList, TOUR_WIDGET_FIELDS.TOUR)}
            onChange={(...args) => handleSelectInputChange(TOUR_WIDGET_FIELDS.TOUR, ...args)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Button
            variant="contained"
            color="primary"
            className="TWV2CheckAvBtn"
            onClick={handleSearchButtonClick}>
            {t('common_tour_widget_v2:check_availability')}
          </Button>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { TourWidgetV2 };
