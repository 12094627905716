import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useMemo, useState } from 'react';
import RidersIcon from 'views/common/components/UI/Icons/RidersIcon';
import { IconedBox } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/IconecBox';
import { WidgetTextFieldV2 } from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/WidgetTextFieldV2/WidgetTextFieldV2';
import StyledBox, {
  StyledMenuBox,
  StyledMenuItem,
} from 'views/rebranded-module/common/components/Logical/MainWidgetV2/components/RiderCount/RiderCount.styles';
import {
  ERRORS_PREFIX,
  RENTAL_WIDGET_FIELDS,
} from 'lib/utils/mainWidgetConstants';
import Typography500 from 'views/common/components/UI/Typography500';
import Bike from 'views/common/components/UI/Icons/Bike';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { Typography700 } from 'views/common/components';
import ArrowIcon from 'views/common/components/UI/Icons/ArrowIcon';
import { VehicalCounter } from 'views/common/components/UI/MainWidget';

const RiderCount = (props) => {
  const { t } = useTranslation('common_rental_widget_v2');
  const {
    errors,
    handleFormDataChange,
    formData,
    motorcycleList,
    disableVehicleCount,
  } = props;

  const theme = useTheme();

  const [openMenu, setOpenMenu] = useState(false);

  const menuProps = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      PaperProps: {
        sx: {
          [theme.breakpoints.down('md')]: {
            top: '0 !important',
            bottom: '0 !important',
            height: '100% !important',
            right: '0 !important',
            left: '0 !important',
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
            borderRadius: 0,
          },
        },
      },
      MenuListProps: {
        style: {
          [theme.breakpoints.down('md')]: {
            postion: 'relative',
            height: '100%',
          },
        },
      },
    }),
    [],
  );

  const isOptionSelected = (option) => +option.number === formData.reservation.vehicles_count
    || (formData.reservation.vehicles_count >= 6 && +option.number === 6);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <StyledBox>
      <IconedBox
        error={!!errors[`${ERRORS_PREFIX}vehicles_count`]}
        icon={<RidersIcon />}>
        <WidgetTextFieldV2
          label={t('common_rental_widget_v2:riders')}
          select
          fullWidth
          className="RCSelectField"
          value={formData.reservation?.vehicles_count}
          error={!!errors[`${ERRORS_PREFIX}vehicles_count`]}
          disabled={disableVehicleCount}
          SelectProps={{
            MenuProps: menuProps,
            open: openMenu,
            onClose: handleCloseMenu,
            onOpen: handleOpenMenu,
            renderValue: (value) => value,
            IconComponent: ArrowIcon,
          }}>
          <>
            <StyledMenuBox
              px={2}
              py={1}
              className="VCloseBtnContainer showMOBILE">
              <IconButton className="VCloseBtn" onClick={handleCloseMenu}>
                <CloseIcon />
              </IconButton>
            </StyledMenuBox>
            <StyledMenuItem className="VCItemTitle" disabled>
              <Typography700 variant="body1">
                {t('common_rental_widget_v2:select_number_motorcycles')}
              </Typography700>
            </StyledMenuItem>
            {motorcycleList.map((option) => (
              <StyledMenuItem
                className={`${option.number === 6 && 'VCCounterMenuItme'} ${
                  isOptionSelected(option) && 'VCItemSelected'
                } `}
                key={+option.number}
                value={+option.number}
                onChange={(e) => {
                  handleFormDataChange(
                    RENTAL_WIDGET_FIELDS.VEHICLES_COUNT,
                    +e.target.value,
                  );
                }}>
                <Box
                  className="VCLableAndDisc"
                  onClick={() => {
                    handleFormDataChange(
                      RENTAL_WIDGET_FIELDS.VEHICLES_COUNT,
                      +option.number,
                    );
                    if (+option.number < 6) {
                      handleCloseMenu();
                    }
                  }}>
                  <FormControlLabel
                    value={+option.number}
                    checked={isOptionSelected(option)}
                    control={<Radio size="small" />}
                    label={`${+option.number === 6 ? '6+' : +option.number} ${
                      +option.number > 1
                        ? t('common_rental_widget_v2:motorcycles')
                        : t('common_rental_widget_v2:motorcycle')
                    } `}
                  />
                  {option.discount && (
                    <Box className="VCItemDiscount">
                      <Typography500 variant="caption">
                        {t('common_rental_widget_v2:off_value', {
                          value: option.discount,
                        })}
                      </Typography500>
                    </Box>
                  )}
                </Box>

                {+option.number === 6 && (
                  <Box className="VCCounterItem">
                    <Box className="VCCounterBike">
                      <Bike className="VCBikeIcon" />
                      <Typography500
                        component="span"
                        variant="caption"
                        className="VCBikeText">
                        {t('common_rental_widget_v2:motorcycle_s', {
                          number:
                            formData.reservation.vehicles_count >= 6
                              ? formData.reservation.vehicles_count
                              : 6,
                        })}
                      </Typography500>
                    </Box>
                    <VehicalCounter
                      vehicleCount={formData.reservation.vehicles_count}
                      handleFormDataChange={handleFormDataChange}
                    />
                  </Box>
                )}
              </StyledMenuItem>
            ))}
            <StyledMenuBox
              px={2}
              py={1}
              className="VCDoneBtnContainer showMOBILE">
              <Button fullWidth variant="contained" onClick={handleCloseMenu}>
                {t('common_rental_widget_v2:done')}
              </Button>
            </StyledMenuBox>
          </>
        </WidgetTextFieldV2>
      </IconedBox>
    </StyledBox>
  );
};

export { RiderCount };
