import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(10, 0, 20, 0),
  [theme.breakpoints.down('xl')]: {
    margin: theme.spacing(8.75, 0, 17.5, 0),
  },
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(7.5, 0, 15, 0),
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(6.25, 0, 12.5, 0),
  },

  '& .CLSCard': {
    position: 'relative',
    overflow: 'hidden',
    height: theme.spacing(100),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    border: '4px solid transparent',
    transition: 'border 0.3s ease-in-out',
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(75),
    },
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(62.5),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(56.25),
    },
    '&:hover': {
      border: `4px solid ${theme.palette.primary.main}`,
      '& .CLSImg': {
        transform: 'scale(1.1)',
      },
      '& .CLSTextcontent': {
        transform: 'translateY(0)',
      },
      '& .CLSCardTitle': {
        transform: 'translateY(0)',
      },
      '& .CLSVideo': {
        zIndex: 1,
      },
    },
  },
  '& .ISOverlyer': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 63.12%, rgba(0, 0, 0, 0.6) 100%)',
  },
  '& .CLSImg': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    transition: 'all 1s ease-in-out',
  },

  '& .CLSVideo': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
  },

  '& .CLSCardContent': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    overflow: 'hidden',
    padding: theme.spacing(0, 8, 4.25, 8),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 5.25, 3.25, 5.25),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4, 2.75, 4),
    },
  },

  '& .CLSTextcontent': {
    transition: 'transform 0.4s',
    transform: 'translateY(100%)',
  },

  '& .CLSCardTitle': {
    color: theme.palette.primary.main,
    transform: 'translateY(-100%)',
    transition: 'transform 0.4s',
    textTransform: 'capitalize',
  },

  '& .CLSCardBtn': {
    transition: 'opacity 0.4s ease-in-out',
    color: theme.palette.common.fogWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .CLSCardBtnText': {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(15),
      letterSpacing: theme.typography.pxToRem(0.75),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(14),
      },
    },
    '& .CLSCardBtnIcon': {
      position: 'relative',
      top: theme.typography.pxToRem(-1),
      color: theme.palette.primary.main,
      transition: 'transform 0.3s ease-in-out',
      '& path': {
        height: theme.typography.pxToRem(12),
      },
    },
    '&:hover': {
      '& .CLSCardBtnIcon': {
        transform: `translateX(${theme.typography.pxToRem(8)})`,
      },
    },
  },

  '& .CLSCardDesc': {
    padding: theme.spacing(4.25, 0),
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3.25, 0),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.75, 0),
    },
  },
}));

export default StyledBox;
