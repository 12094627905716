import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .IBIcon': {
    position: 'absolute',
    left: theme.spacing(3),
    top: '50%',
    transform: 'translateY(-42%)',
  },
  '& .MuiSvgIcon-root': {
    height: 'auto',
    width: theme.typography.pxToRem(24),
  },
  '& .IBError .MuiSvgIcon-root path': {
    fill: theme.palette.error.main,
  },
}));

export { StyledBox };
